<template>
  <div>
    <NavBar />
    <div class="page-container">
      <div class="card-list-container">
        <div class="top-right">
          <input
              v-if="checkCardProducentToken()"
              type="button"
              class="add-card-button"
              @click="$router.push({ name: 'addcard' })"
              value="Add Card"
          />
        </div>
        <div class="card-list-wrapper">
          <template v-if="loading">
            Loading...
          </template>
          <template v-else>
            <div v-for="(category, categoryIndex) in groupedCards" :key="categoryIndex">
              <h2>{{ category.category }}</h2>
              <div class="card-container">
                <router-link
                    v-for="card in category.cards"
                    :key="card.id"
                    :to="getCardLink(card)"
                    class="card"
                >
                  <img :src="card.imageUrl" alt="Card Image" />
                  <i v-if="!checkCardProducentToken()" class="fas fa-info-circle icon"></i>
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";

export default {
  name: "CardListPage",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      cards: [],
      cardProducentToken: localStorage.getItem("cardProducentToken"),
      VUE_APP_CARD_PRODUCENT_TOKEN: process.env.VUE_APP_CARD_PRODUCENT_TOKEN,
    };
  },
  computed: {
    groupedCards() {
      // Group cards by category
      const grouped = this.cards.reduce((result, card) => {
        const categoryIndex = result.findIndex((group) => group.category === card.category);

        if (categoryIndex === -1) {
          result.push({ category: card.category, cards: [card] });
        } else {
          result[categoryIndex].cards.push(card);
        }

        return result;
      }, []);

      return grouped;
    },
  },
  mounted() {
    // Fetch data from the /api/card endpoint
    this.fetchCards();
  },
  methods: {
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cards = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching cards:", error);
        this.loading = false;
      }
    },
    getCardLink(card) {
      const cardProducentToken = localStorage.getItem("cardProducentToken");

      if (cardProducentToken === "false") {
        // Redirect to /card/details if cardProducentToken is false
        return { name: "detailcard", query:{ id: card.id} };
      } else if (cardProducentToken === process.env.VUE_APP_CARD_PRODUCENT_TOKEN) {
        // Redirect to /editcard with query parameter if cardProducentToken matches
        return { name: "editordetailcard", query: { id: card.id } };
      } else {
        // Handle any other conditions as needed
        return { name: "defaultRoute" };
      }
    },
    checkCardProducentToken() {
      // Return true if cardProducentToken matches the predefined token
      return localStorage.getItem("cardProducentToken") === process.env.VUE_APP_CARD_PRODUCENT_TOKEN;
    },
  },
};
</script>

<style scoped>
.page-container {
  position: relative;
  height: 90vh; /* Set the height of the container to 100% of the viewport height */
  overflow: hidden; /* Hide overflow from the container */
}

.card-list-container {
  text-align: center;
  margin: 50px;
}

.card-list-wrapper {
  max-height: calc(100vh - 180px); /* Adjust the height as needed, considering other elements on the page */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the height */
  margin-top: 80px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 50px;
}

.card {
  flex: 0 1 calc(10% - 10px);
  border: 1px solid #ddd;
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin-bottom: 3px;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-card-button {
  margin-bottom: 20px;
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.add-card-button:hover {
  background-color: #2980b9;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

.card {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #3498db; /* Change the color as needed */
  font-size: 20px; /* Adjust the font size as needed */
  visibility: hidden; /* Initially hide the icon */
}

.card:hover {
  border-color: #3498db;
}

.card:hover .icon {
  visibility: visible; /* Show the icon when the card is being hovered */
}

h2{
  text-align: left;
  margin: 0;
}

</style>
