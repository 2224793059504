<template>
  <div>
    <NavBar />
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
    <div class="update-company-container">
      <h1>Edit Company</h1>
      <form @submit.prevent="updateCompany">
        <div class="form-field">
          <label for="companyName">*Company Name:</label>
          <input class="inputfield" v-model="name" type="text" id="companyName" required>
        </div>
        <div class="form-field">
          <label for="logo">*Logo URL:</label>
          <input class="inputfield" v-model="logoUrl" type="text" id="logo" required>
        </div>
        <div class="form-field">
          <label>*Default Card:</label>
          <div class="custom-dropdown" @click="toggleDropdown">
            <img v-if="selectedCard" :src="selectedCard.imageUrl" alt="Card Image" class="card-image"/>
            {{ selectedCard ? selectedCard.title : '' }}
            <div class="dropdown-content" v-show="dropdownVisible">
              <div v-for="card in cardList" :key="card.id" @click.stop="selectCard(card)">
                <img :src="card.imageUrl" alt="Card Image" class="card-image select-card-image"/>
                <p class="selectTitle">{{ card.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <label>Default Text On Card:</label>
          <div class="text-input-container">
            <input class="inputfield textinputfield" v-model="defaultText"
                   placeholder="Happy birthday from everyone!" :maxlength="maxTextLength"/>
            <span class="character-count">{{ defaultText.length }}/{{ maxTextLength }}</span>
          </div>
        </div>
        <button class="save-button" type="submit">Update</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "CompanyEditPage",
  components: {
    NavBar,
  },
  data() {
    return {
      name: "",
      logoUrl: "",
      defaultCard: "",
      companyId: null,
      selectedCard: {},
      dropdownVisible: false,
      cardList: [],
      defaultText: "",
      maxTextLength: 50
    };
  },
  mounted() {
    // Assuming you have a query parameter for companyId
    this.companyId = this.$route.query.companyId;
    // Fetch company details and fill in the form
    this.fetchCompanyDetails();
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back one step in the history
    },
    fetchCompanyDetails() {
      axios.get(`/api/companies/${this.companyId}`)
          .then(response => {
            const company = response.data;
            // Fill in the form fields with company details
            this.name = company.name;
            this.logoUrl = company.logoUrl;
            this.defaultCard = company.defaultCard;
            this.defaultText = company.defaultText;
            // Fetch card list
            this.fetchCards();
          })
          .catch(error => {
            console.error('Error fetching company details:', error);
          });
    },
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cardList = response.data;
        if (this.cardList.length > 0) {
          this.selectedCard = this.cardList.find(card => card.id === this.defaultCard);
        }
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },

    selectCard(card) {
      this.selectedCard = card;
      this.dropdownVisible = false;
      localStorage.setItem('defaultCardId', card.id);
    },
    updateCompany() {
      const companyData = {
        name: this.name,
        logoUrl: this.logoUrl,
        defaultCard: this.selectedCard.id,
        defaultText: this.defaultText
      };

      axios.put(`/api/companies/${this.companyId}`, companyData)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Company updated",
              showConfirmButton: false,
              timer: 1500,
            });
            router.push("/company");
          })
          .catch((error) => {
            console.error("Error updating company:", error);
            Swal.fire({
              icon: "error",
              title: "Error updating company",
              showConfirmButton: false,
              timer: 1500,
            });
          });
    },
  },
};
</script>

<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}
.update-company-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 30vw;
  margin: 50px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.form-field {
  position: relative;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  cursor: pointer;
  position: relative; /* Added position relative to contain the pseudo-element */
  background-color: white;
  font-size: 0.9em;
}

.custom-dropdown::after {
  content: '\25BC'; /* Unicode for a right-pointing triangle */
  font-size: 0.8em;
  margin-left: auto;
  margin-right: 10px;
  color: #3498db; /* Color of the arrow */
}

.custom-dropdown:hover {
  background-color: #ecf0f1;
}

.dropdown-content {
  display: none;
  position: absolute; /* Keep the absolute positioning */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  overflow: auto;
  max-height: 500px;
  top: 100%; /* Position it below the custom-dropdown */
  left: 0;
}

.custom-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content img {
  transform: none;
  margin-left: 0;
  transition: none;
}

.dropdown-content div:hover img {
  position: fixed;
  top: 45%;
  left: 36.4%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
}

.dropdown-content div:hover p {
  margin-left: 18%;
}

.dropdown-content div p {
  margin-left: 0;
  text-align: center;
}

.dropdown-content div:hover {
  background-color: #ecf0f1;
}

.selectTitle {
  margin-left: 30%;
}

.text-input-container {
  position: relative;
}

.character-count {
  position: absolute;
  top: 15px;
  right: -40px;
  color: #3498db;
  font-size: 0.8em;
}

.textinputfield {
  margin-left: 0;
}

@media (max-width: 1000px) {
  .update-company-container {
    width: 50vw;
  }
  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .update-company-container {
    width: 80vw;
  }
  .inputfield {
    width: 60vw;
  }
}
</style>
