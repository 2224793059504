<template>
  <div class="container">
    <div class="content">
      <div class="login-container animated fadeInDown" style="animation-delay:.3s;">
        <div class="login justify-content-center" id="login-form">
          <h1 class="form-title"><i class="fas fa-user" style="color:#55a0ff;"></i> <br> LOGIN
            <hr>
          </h1>

          <div class="form-container animated fadeIn" style="animation-delay:.7s;">
            <form method="POST" @submit.prevent="handleLogin" action="{{ route('login') }}">

              <label><i class="fas fa-at"></i> Email </label>
              <div class="input-container">
                <input v-model="loginData.email" type="email" name="email" placeholder="E-mail" required>
              </div>

              <label><i class="fab fa-hashtag"></i> Password </label>
              <div class="input-container password-input">
                <input v-model="loginData.password" :type="passwordFieldType" name="password" placeholder="Password"
                       required>
                <span @click="togglePasswordVisibility">
                  <i :class="passwordFieldType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </span>
              </div>

              <div class="submit-buttons">
                <input type="submit" value="Login">
                <input type="button" @click="$router.push('/register')" value="Sign Up">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: "LoginPage",
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    async handleLogin() {
      localStorage.removeItem('token');
      localStorage.removeItem('hrId');
      localStorage.removeItem('companyId');

      try {
        const encoder = new TextEncoder();
        const data = encoder.encode(this.loginData.password);
        const hashedInputPasswordBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashedInputPasswordArray = Array.from(new Uint8Array(hashedInputPasswordBuffer));
        const hashedInputPassword = hashedInputPasswordArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        const response = await axios.post('/api/token/login', {
          email: this.loginData.email,
          password: hashedInputPassword,
        });

        const token = response.data.token;
        localStorage.setItem('token', token);

        // Wait for the token to be stored in local storage
        await new Promise(resolve => setTimeout(resolve, 100));

        const storedToken = localStorage.getItem('token');

        const config = {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        };

        if (storedToken) {
          // Use the stored token for subsequent requests
          await axios.get('/api/hr', config)
              .then(async response => {
                const hrWithCredentials = response.data.find(hr => hr.email === this.loginData.email);
                if (hrWithCredentials && hrWithCredentials.password === hashedInputPassword) {
                  // HR person found, proceed with login
                  localStorage.setItem('hrId', hrWithCredentials.id);

                  // Check if the HR has a company

                  try {
                    const companyId = await this.getCompanyId(hrWithCredentials.id);

                    // Update companyId in local storage
                    localStorage.setItem('companyId', companyId);
                    const companyDetails = await this.getCompanyDetails(companyId);

                    localStorage.setItem('defaultCardId', companyDetails.defaultCard);


                    Swal.fire({
                      icon: 'success',
                      title: 'Login successful!',
                      showConfirmButton: false,
                      timer: 1500,
                    });

                    // Redirect to the appropriate route

                    if(hrWithCredentials.email === process.env.VUE_APP_CARD_PRODUCENT_EMAIL){
                      await localStorage.setItem('cardProducentToken', 'true');
                      localStorage.setItem('tosterAdminToken', 'false')
                      this.$router.push('/employees/birthdaynextmonth');
                    }else if(hrWithCredentials.email === process.env.VUE_APP_TOSTER_ADMIN_EMAIL){
                      localStorage.setItem('cardProducentToken', 'false')
                      localStorage.setItem('tosterAdminToken', 'true')
                      this.$router.push('/animation');
                    }else{
                      localStorage.setItem('cardProducentToken', 'false')
                      localStorage.setItem('tosterAdminToken', 'false')
                      this.$router.push('/company/employees');
                    }
                  } catch (companyError) {
                    console.log('Error fetching company data:', companyError);

                    // If there is no company associated, redirect to create company page
                    if (companyError.response && companyError.response.status === 404) {
                      if(hrWithCredentials.email === process.env.VUE_APP_CARD_PRODUCENT_EMAIL){
                        await localStorage.setItem('cardProducentToken', process.env.VUE_APP_CARD_PRODUCENT_TOKEN)
                        localStorage.setItem('tosterAdminToken', 'false')
                        this.$router.push('/employees/birthdaynextmonth');
                      }else if(hrWithCredentials.email === process.env.VUE_APP_TOSTER_ADMIN_EMAIL){
                        localStorage.setItem('cardProducentToken', 'false')
                        localStorage.setItem('tosterAdminToken', process.env.VUE_APP_TOSTER_ADMIN_TOKEN)
                        this.$router.push('/animation');
                      }else {
                        this.$router.push('/company/add');
                        localStorage.setItem('tosterAdminToken', 'false')
                        localStorage.setItem('cardProducentToken', 'false')
                      }
                    } else {
                      // Handle other errors as needed
                      Swal.fire({
                        icon: 'error',
                        title: 'Error fetching company data',
                        showConfirmButton: false,
                        timer: 2000,
                      });
                    }
                  }
                } else {
                  // HR person not found or password does not match, show an error
                  Swal.fire({
                    icon: 'error',
                    title: 'Credentials don\'t match',
                    showConfirmButton: false,
                    timer: 2000
                  });
                }
              })
              .catch(error => {
                console.error('Error fetching HR data:', error);
              });
        } else {
          console.error('No token');
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    async getCompanyId(hrId) {
      try {
        const response = await axios.get(`/api/companies/getCompanybyhrId/${hrId}`);
        const companyId = response.data.id;

        localStorage.setItem('companyId', companyId);

        return companyId;
      } catch (error) {
        console.error('Error fetching company data:', error);
        throw error;
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async getCompanyDetails(companyId) {
      try {
        const response = await axios.get(`/api/companies/${companyId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching company details:', error);
        throw error;
      }
    },
  },
};
</script>

<style scoped>
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-container {
  background: white;
  position: relative;
  border-radius: 5px;
  display: flex;
  width: 30vw;
  height: 70vh;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.40);
  transition: .4s;
}


.login {
  transition: .4s;
  font-family: Tahoma, serif;
}

.login#login-form {
  width: 100%;
  display: block;
  padding: 15px;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 20px;
}


.form-title {
  position: absolute;
  top: 11%;
  left: 0;
  right: 0;
  text-align: center;
  color: darkgrey;
  font-size: 2.2em;
  font-family: Montserrat, serif;
  line-height: 1.4;
  margin-top: -24px;
}

.form-title hr {
  width: 75%;
  border: 2px solid #dedede1f;
  border-radius: 10px;
  margin-top: 20px;
}

.register .form-title {
  margin-top: -54px;
}


.form-container {
  padding: 25px 10px;
  border-radius: 5px;
  width: 80%;
  text-align: left;
  margin-top: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


label {
  font-size: .7em;
  color: #7392ff;
  margin: 0px 0 !important;
  margin-left: 4px !important;
}

input {
  background: #f8fafc;
  border: 0;
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px !important;
  outline: 0;
  transition: .4s;
}

input::placeholder {
  opacity: .4;
}

.submit-buttons {
  display: flex;
}

.submit-buttons input {
  width: 50%;
  text-align: center;
  color: white;
  transition: .2s;
}

.submit-buttons input:hover {
  opacity: .7;
}

.submit-buttons input:nth-of-type(1) {
  border-radius: 5px 0 0 5px;
  background: #54a0ff;
}

.submit-buttons input:nth-of-type(2) {
  border-radius: 0 5px 5px 0;
  background: #c8d6e5;
}

.password-input span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.input-container input,
.password-input input,
.email-input input{
  width: 100%;
  box-sizing: border-box;
}

.password-input {
  position: relative;
}

@media (max-width: 1000px) {
  .login-container {
    width: 50vw;
  }
}

@media (max-width: 600px) {
  .login-container {
    width: 80vw;
  }
}
</style>
