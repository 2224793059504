<template>
  <div class="container">
    <div class="content">
      <div class="login-container animated fadeInDown" style="animation-delay:.3s;">
        <div class="register justify-content-cente animatedr" style="animation-delay:.3s">
          <h1 class="form-title "><i class="fas fa-user-plus" style="color:#57efc4;"></i> <br> REGISTER
            <hr>
          </h1>

          <div class="form-container animated fadeIn" style="animation-delay:.7s;">
            <form @submit.prevent="handleSave">
              <label><i class="fas fa-at"></i> Email </label>
              <div class="email-input">
                <input v-model="hr.email" type="email" name="email" placeholder="E-mail" required >
              </div>

              <label><i class="fab fa-hashtag"></i> Password </label>
              <div class="password-input">
                <input v-model="hr.password" :type="passwordFieldType" name="password" placeholder="Password" required>
                <span @click="togglePasswordVisibility">
                  <i :class="passwordFieldType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </span>
              </div>

              <label><i class="fab fa-hashtag"></i> Confirm Password </label>
              <div class="password-input">
                <input v-model="confirmpassword" :type="passwordFieldType" name="password_confirmation"
                       placeholder="Password" required>
                <span @click="togglePasswordVisibility">
                  <i :class="passwordFieldType === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                </span>
              </div>

              <div class="submit-buttons">
                <input :disabled="isSaving" type="submit" value="Register" style="background:#55efc4;">
                <input type="button" value="Sign In" @click="$router.push('/')" class="btn-login">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';
import router from "@/router";

export default {
  name: "RegisterPage",
  data() {
    return {
      hr: {
        email: '',
        password: '',
      },
      confirmpassword: '',
      isSaving: false,
      passwordFieldType: 'password',
    };
  },
  methods: {
    handleSave() {
      if (this.hr.password === this.confirmpassword) {
        const passwordCheckResult = this.checkPasswordStrength(this.hr.password);

        if (passwordCheckResult.isValid) {
          this.isSaving = true;
          const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          };

          axios.post('/api/hr', this.hr, {headers})
              .then(response => {
                Swal.fire({
                  icon: 'success',
                  title: 'Successfully registered!',
                  showConfirmButton: false,
                  timer: 1500
                });
                this.isSaving = false;
                this.hr.email = "";
                this.hr.password = "";
                this.confirmpassword = "";
                router.push('/');
                return response;
              })
              .catch(error => {
                this.isSaving = false;
                if (error.response && error.response.status === 409) {
                  // User with this email already exists
                  Swal.fire({
                    icon: 'error',
                    title: 'User with this email already exists',
                    showConfirmButton: false,
                    timer: 2000,
                  });
                } else {
                  // Other errors
                  Swal.fire({
                    icon: 'error',
                    title: 'An Error Occurred!',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                return error;
              });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Password not strong enough',
            text: `Password must meet the following criteria: ${passwordCheckResult.weaknesses.join(', ')}`,
            showConfirmButton: true,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Password and confirm password are not the same',
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    checkPasswordStrength(password) {
      const minLength = 6;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasDigit = /\d/.test(password);
      const hasSymbol = /[!@#$%^&*()_+{}:;<>,.?~\\/-]/.test(password);

      const isValid = (
          password.length >= minLength &&
          hasUpperCase &&
          hasLowerCase &&
          hasDigit &&
          hasSymbol
      );

      const weaknesses = [];
      if (password.length < minLength) {
        weaknesses.push('at least 6 characters');
      }
      if (!hasUpperCase) {
        weaknesses.push('at least one uppercase letter');
      }
      if (!hasLowerCase) {
        weaknesses.push('at least one lowercase letter');
      }
      if (!hasDigit) {
        weaknesses.push('at least one digit');
      }
      if (!hasSymbol) {
        weaknesses.push('at least one special character');
      }

      return {
        isValid,
        weaknesses,
      };
    },
  },
};
</script>

<style scoped>
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-container {
  background: white;
  position: relative;
  border-radius: 5px;
  display: flex;
  width: 30vw;
  height: 70vh;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.40);
  transition: .4s;
}

.register {
  width: 100%;
  display: block;
  padding: 15px;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 30px;
  transition: .4s;
  font-family: Tahoma, sans-serif;
}


.form-title {
  position: absolute;
  top: 11%;
  left: 0;
  right: 0;
  text-align: center;
  color: darkgrey;
  font-size: 2.2em;
  font-family: Montserrat, sans-serif;
  line-height: 1.4;
  margin-top: -24px;
}

.form-title hr {
  width: 75%;
  border: 2px solid #dedede1f;
  border-radius: 10px;
  margin-top: 20px;
}

.register .form-title {
  margin-top: -54px;
}


.form-container {
  padding: 25px 10px;
  border-radius: 5px;
  width: 80%;
  text-align: left;
  margin-top: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


label {
  font-size: .7em;
  color: #7392ff;
  margin: 0px 0 !important;
  margin-left: 4px !important;
}

input {
  background: #f8fafc;
  border: 0;
  width: 100%;
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px !important;
  outline: 0;
  transition: .4s;
}

input::placeholder {
  opacity: .4;
}

.submit-buttons {
  display: flex;
}

.submit-buttons input {
  width: 50%;
  text-align: center;
  color: white;
  transition: .2s;
}

.submit-buttons input:hover {
  opacity: .7;
}

.submit-buttons input:nth-of-type(1) {
  border-radius: 5px 0 0 5px;
  background: #54a0ff;
}

.submit-buttons input:nth-of-type(2) {
  border-radius: 0 5px 5px 0;
  background: #c8d6e5;
}

.password-input {
  position: relative;
}

.password-input span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.input-container input,
.password-input input,
.email-input input{
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .login-container {
    width: 50vw;
  }
}

@media (max-width: 600px) {
  .login-container {
    width: 80vw;
  }
}
</style>
