<template>
  <div>
    <NavBar />

    <h1>Cards next month</h1>
    <ul class="company-list">
      <div class="container" v-for="(companyEmployees, index) in groupedEmployees" :key="'company-group-' + index">
        <li class="company-card">
          <div class="button-container">
            <input
                type="button"
                value="Download"
                @click="downloadEmployees(companyEmployees, companyNames[index])"
            />
          </div>
          <div class="content-container">
            <span class="company-name">{{ companyNames[index] }}</span>
            <ul class="employee-list">
              <h4>Birthdays</h4>
              <li v-for="employee in companyEmployees" :key="employee.id">
                {{ employee.firstName }} {{ employee.lastName }} - Birthday: {{ formatDate(employee.birthDay) }}
              </li>
              <h4 class="onetimecard-title">One-timecards</h4>
              <li v-for="onetimecard in onetimecards[index]" :key="onetimecard.id">
                {{ getEmployeeFullName(onetimecard.employeeId) }} - {{ onetimecard.category }}: {{ formatDate(onetimecard.date) }}
              </li>
            </ul>
          </div>
        </li>
      </div>
      <li v-if="groupedEmployees.length > 1" class="group-spacing"></li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
import NavBar from "@/components/NavBar";
import QRCode from "qrcode";
import JSZip from 'jszip';
import {labelXml} from "@/labelXml";
const Dymo = require('dymojs'), dymo = new Dymo();

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      employees: [],
      companyNames: [],
      onetimecards: [],
      printers: [],
    };
  },
  computed: {
    groupedEmployees() {
      return this.groupEmployeesByCompany(this.filterEmployees(this.employees));
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Fetch both employees and onetimecards data concurrently
        const [employeesResponse, onetimecardsResponse] = await Promise.all([
          axios.get('/api/employees'),
          axios.get('/api/onetimecard'), // Adjust the API endpoint accordingly
        ]);

        this.employees = employeesResponse.data;
        this.onetimecards = onetimecardsResponse.data;

        // Group onetimecards by company using the fetched information
        await this.groupOnetimecardsByCompany(this.onetimecards);

        // Group employees by company as before
        const promises = this.groupedEmployees.map(group => this.getCompanyName(group[0].companyId));
        this.companyNames = await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async getCompanyName(companyId) {
      try {
        const response = await axios.get(`/api/companies/${companyId}`);
        return response.data.name;
      } catch (error) {
        console.error(`Error fetching company name for ID ${companyId}:`, error);
        return 'Unknown Company';
      }
    },
    getEmployeeFullName(employeeId) {
      const employee = this.employees.find(emp => emp.id === employeeId);
      return employee ? `${employee.firstName} ${employee.lastName}` : 'Unknown Employee';
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    filterEmployees(employees) {
      const currentDate = new Date();
      const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);

      const filteredEmployees = employees.filter(employee => {
        const employeeBirthday = new Date(employee.birthDay);
        return (employeeBirthday.getMonth() === nextMonthDate.getMonth());
      });

      filteredEmployees.sort((a, b) => {
        const dateA = new Date(a.birthDay);
        const dateB = new Date(b.birthDay);
        return dateA.getDate() - dateB.getDate();
      });

      return filteredEmployees;
    },
    groupEmployeesByCompany(employees) {
      const groupedEmployees = {};
      employees.forEach(employee => {
        const companyId = employee.companyId;

        if (!groupedEmployees[companyId]) {
          groupedEmployees[companyId] = [];
        }

        groupedEmployees[companyId].push(employee);
      });
      return Object.values(groupedEmployees);
    },
    async groupOnetimecardsByCompany(onetimecards) {
      const groupedOnetimecards = {};

      await Promise.all(onetimecards.map(async (onetimecard) => {
        const employeeResponse = await axios.get(`/api/employees/${onetimecard.employeeId}`);
        const companyId = employeeResponse.data.companyId;

        if (!groupedOnetimecards[companyId]) {
          groupedOnetimecards[companyId] = [];
        }

        // Only include onetimecards from the next month
        const onetimecardDate = new Date(onetimecard.date);
        const currentDate = new Date();
        const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);

        if (onetimecardDate.getMonth() === nextMonthDate.getMonth()) {
          groupedOnetimecards[companyId].push(onetimecard);
        }
      }));

      // Sort onetimecards by date within each company
      for (const companyId in groupedOnetimecards) {
        groupedOnetimecards[companyId].sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
      }

      this.onetimecards = Object.values(groupedOnetimecards);
    },

    async getPersonalisationInfo(employeeId){
      try{
        const personalisationResponse = await axios.get(`/api/personalisation/GetPersonalisationByEmpoyeeId/${employeeId}`);
        return personalisationResponse.data;
      } catch(error){
        console.error(`Error fetching personalisation info for employee ID ${employeeId}:`, error);
        return {text: 'Unknown Text'};
      }
    },
    async getCardInfo(employeeId) {
      try {
        const personalisationResponse = await axios.get(`/api/personalisation/GetPersonalisationByEmpoyeeId/${employeeId}`);
        const cardId = personalisationResponse.data.card;
        const cardResponse = await axios.get(`/api/card/${cardId}`);
        return cardResponse.data;
      } catch (error) {
        console.error(`Error fetching card info for employee with ID ${employeeId}:`, error);
        return { title: 'Unknown Title' }; // Replace with a default title or handle the error as needed
      }
    },
    async getAnimationInfo(employeeId) {
      try {
        const personalisationResponse = await axios.get(`/api/personalisation/GetPersonalisationByEmpoyeeId/${employeeId}`);
        const animationId = personalisationResponse.data.animation;
        const animaitonResponse = await axios.get(`/api/animation/${animationId}`);
        return animaitonResponse.data;
      } catch (error) {
        console.error(`Error fetching card info for employee with ID ${employeeId}:`, error);
        return { title: 'Unknown Title' }; // Replace with a default title or handle the error as needed
      }
    },
    async downloadEmployees(employees, companyName) {
      try {
        const currentDate = new Date();
        const companyId = employees[0].companyId; // Assuming all employees belong to the same company

        // Function to fetch onetimecard employee details
        const fetchOnetimecardEmployeeDetails = async (onetimecard) => {
          try {
            const employeeResponse = await axios.get(`/api/employees/${onetimecard.employeeId}`);
            const onetimecardCompanyId = employeeResponse.data.companyId;

            if (onetimecardCompanyId === companyId) {
              if (onetimecard && onetimecard.employeeId) {
                const onetimecardInfo = await this.getOnetimecardInfo(onetimecard.id);
                if (onetimecardInfo) {
                  const employee = employeeResponse.data;
                  const personalisationInfo = await this.getPersonalisationInfo(onetimecard.employeeId);
                  //const animationInfo = await this.getAnimationInfo(onetimecard.employeeId);
                  const animationInfo = await axios.get(`/api/animation/${onetimecardInfo.animation}`);
                  const animationName = animationInfo.data.name;
                  const websiteLink = process.env.VUE_APP_TOSTER_WEBSITE_LINK;

                  // Generate QR code data
                  const qrCodeData = `${websiteLink}?text=${encodeURIComponent(onetimecardInfo.text)}&model=${animationName}`;

                  // Generate QR code image
                  const qrCodeImage = await QRCode.toDataURL(qrCodeData);

                  return {
                    employeeId: employee.id,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    type: 'Onetimecard',
                    qrCodeImage,
                    ageNextMonth: 'N/A',
                    address: employee.address,
                    cardInfo: onetimecardInfo,
                    personalisationInfo,
                    animationInfo,
                    url: qrCodeData.replaceAll('&', '&amp;'),
                  };
                } else {
                  console.error('Error fetching onetimecard info for ID:', onetimecard.id);
                }
              } else {
                console.error('Invalid onetimecard:', onetimecard);
              }
            }else {
              console.warn(`Skipping onetimecard for employee ${onetimecard.employeeId} belonging to another company`);
              return null; // Skip onetimecards of other companies
            }
          } catch (error) {
            console.error('Error in fetchOnetimecardEmployeeDetails:', error);
          }
        };

        // Fetch onetimecard employee details concurrently
        const onetimecardPromises = this.onetimecards.flat().map(fetchOnetimecardEmployeeDetails);
        const onetimecardEmployeeDetails = await Promise.all(onetimecardPromises.filter(Boolean));

        // Include onetimecard employees in the QR code promises
        const qrCodePromises = employees.map(async (employee) => {
          try {
            const personalisationInfo = await this.getPersonalisationInfo(employee.id);
            const cardInfo = await this.getCardInfo(employee.id);
            const animationInfo = await this.getAnimationInfo(employee.id);
            const animationName = animationInfo.name;
            const websiteLink = process.env.VUE_APP_TOSTER_WEBSITE_LINK;


            const birthday = new Date(employee.birthDay);
            let ageNextMonth;
            if (birthday.getMonth() === 0) {
              ageNextMonth = currentDate.getFullYear() - birthday.getFullYear() + 1;
            } else {
              ageNextMonth = currentDate.getFullYear() - birthday.getFullYear();
            }

            // Generate QR code data
            const qrCodeData = `${websiteLink}?age=${ageNextMonth}&text=${encodeURIComponent(personalisationInfo.text)}&model=${animationName}`;

            // Generate QR code image
            const qrCodeImage = await QRCode.toDataURL(qrCodeData);

            return {
              employeeId: employee.id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              type: 'Birthday',
              qrCodeImage,
              ageNextMonth: ageNextMonth,
              address: employee.address,
              cardInfo,
              personalisationInfo,
              animationInfo,
              birthDay: employee.birthDay,
              url: qrCodeData.replaceAll('&', '&amp;'),
            };
          } catch (error) {
            console.error('Error in qrCodePromises:', error);
          }
        });

        const qrCodeImages = await Promise.all(qrCodePromises.concat(onetimecardEmployeeDetails));

        const validQrCodeImages = qrCodeImages.filter(entry => entry !== null);

        // Combine both birthday and onetimecard employees in the same array
        const excelData = validQrCodeImages.map(async (employee) => {
          try {
            if (employee) {
              const animationName = await this.getAnimationName(employee.type === 'Birthday' ? employee.animationInfo.id : employee.cardInfo.animation);
              const productNumber = employee.type === 'Birthday' ? employee.cardInfo.productNumber : await this.getProductNumber(employee.cardInfo.card);
              let birthDayDate = new Date(employee.birthDay);

              birthDayDate.setFullYear(currentDate.getFullYear(), birthDayDate.getMonth(), birthDayDate.getDate());

              return {
                'First Name': employee.firstName,
                'Last Name': employee.lastName,
                'Type': employee.type || 'Unknown Type', // Add a default value if 'type' is undefined
                'Date': employee.type === 'Birthday' ? this.formatDate(new Date(birthDayDate)) : this.formatDate(new Date(employee.cardInfo.date)),
                'Age Next Month': employee.ageNextMonth,
                'Address': employee.address,
                'Card Product number': productNumber,
                'Text': employee.type === 'Birthday' ? employee.personalisationInfo.text : employee.cardInfo.text,
                'Animation Name': animationName,
                'QR Code Image': employee.qrCodeImage,
                'URL': employee.url,
              };
            } else {
              console.error('Error: Employee data is undefined or null');
              return null; // Return a default value or handle the error accordingly
            }
          } catch (error) {
            console.error('Error in excelData:', error);
            return null; // Return a default value or handle the error accordingly
          }
        });

// Wait for all promises to resolve and then download the Excel file
        Promise.all(excelData).then((data) => {
          // Filter out entries with null values
          const filteredData = data.filter(entry => entry !== null);

          // Generate and download Excel file
          this.downloadExcel(filteredData, companyName);

          // Optionally, you can provide a way to download the QR codes
          this.downloadQRCodeImages(validQrCodeImages, companyName);
        });


      } catch (error) {
        console.error('Error in downloadEmployees:', error);
      }
    },
    async getAnimationName(animationId) {
      try {
        const animationResponse = await axios.get(`/api/animation/${animationId}`);
        return animationResponse.data.name;
      } catch (error) {
        console.error(`Error fetching animation name for ID ${animationId}:`, error);
        return 'Unknown Animation';
      }
    },
    async getProductNumber(cardId) {
      try {
        const cardResponse = await axios.get(`/api/card/${cardId}`);
        return cardResponse.data.productNumber;
      } catch (error) {
        console.error(`Error fetching product number for card ID ${cardId}:`, error);
        return 'Unknown Product Number';
      }
    },
    async getOnetimecardInfo(onetimecardId) {
      try {
        const onetimecardResponse = await axios.get(`/api/onetimecard/${onetimecardId}`);
        return onetimecardResponse.data;
      } catch (error) {
        console.error(`Error fetching onetimecard info for ID ${onetimecardId}:`, error);
        return { category: 'Unknown Category' };
      }
    },
    downloadExcel(data, companyName) {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Employees');
      XLSX.writeFile(wb, `${companyName}_Employees.xlsx`);
    },
    async downloadQRCodeImages(qrCodeImages, companyName) {
      const zip = new JSZip();
      const fetchPromises = [];

      for (const qrCode of qrCodeImages) {
        const index = qrCodeImages.indexOf(qrCode);
        const fileName = `${index + 1} - ${companyName} - ${qrCode.firstName} ${qrCode.lastName} - QR Code.png`;

        // Add the QR code image to the zip file
        const fetchPromise = fetch(qrCode.qrCodeImage).then((res) => res.blob());
        zip.file(fileName, fetchPromise);

        // Print the QR code on the Dymo printer
        this.printQRCodeDymo(qrCode.url);

        fetchPromises.push(fetchPromise);
      }

      // Wait for all fetch promises to resolve
      await Promise.all(fetchPromises);

      // Generate and download the zip file
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const zipLink = document.createElement('a');
        zipLink.href = URL.createObjectURL(content);
        zipLink.download = `${companyName}_QRCodes.zip`;
        document.body.appendChild(zipLink);
        zipLink.click();
        document.body.removeChild(zipLink);
      });
    },

    printQRCodeDymo(qrCodeUrl) {
      let updatedLabelXml = labelXml.replace('<DataString>URL:www.toster.be</DataString>', `<DataString>URL:${qrCodeUrl}</DataString>`);
      updatedLabelXml = updatedLabelXml.replace('<DataString>www.toster.be</DataString>', `<DataString>${qrCodeUrl}</DataString>`);
      dymo.print('DYMO LabelWriter 450', updatedLabelXml);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 2em;
  color: #3498db;
}

ul {
  list-style-type: none;
  padding: 0;
}

.group-spacing {
  height: 20px;
}

.company-list {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
}

.container {
  margin: 0 20px 20px 20px; /* Add some margin around each company card */
}

.company-card {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 20vw;
  margin-right: 20px; /* Add some margin between company cards */
  height: 30vh;
}

.company-name {
  font-weight: bold;
  font-size: 1.5em;
}

.employee-list li {
  margin: 5px;
}
input {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
}

.button-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px; /* Adjust margin as needed */
}

.employee-list {
  margin-top: 15px;
  padding: 0;
  list-style-type: none;
  height: 25vh; /* Adjust the max height as needed */
  overflow-y: auto;
  overflow-x: hidden;
}
h4{
  margin: 0;
  padding: 0;
  color: #3498db;
}
.onetimecard-title{
  margin-top: 20px;
}
</style>
