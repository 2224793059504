<template>
  <div class="toggle-container">
    <label :class="{ 'toggle-label': true, 'left': true, 'active': !isAnimationMode }">Cards</label>
    <label :class="{ 'toggle-label': true, 'right': true, 'active': isAnimationMode }">Animations</label>
    <AnimationListPage v-if="isAnimationMode"></AnimationListPage>
    <CardListPage v-else></CardListPage>
    <label class="toggle-switch">
      <input type="checkbox" v-model="isAnimationMode"/>
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
import AnimationListPage from "@/components/AnimationListPage";
import CardListPage from "@/components/CardListPage";

export default {
  name: "AvailableCardsAndAnimationsPage",
  components: { AnimationListPage, CardListPage },
  data() {
    return {
      isAnimationMode: false,
    };
  },
};
</script>

<style scoped>
.toggle-container {
  position: relative;
}

.toggle-switch {
  position: absolute;
  top: 8vh;
  right: 10vw;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3498db;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3498db;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3498db;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  position: absolute;
  top: 8vh;
  font-size: 14px;
}

.left {
  right: 13.5vw;
  margin-top: 10px;
  color: #888; /* Default color for left label */
}

.right {
  right: 6vw;
  margin-top: 10px;
  color: #888; /* Default color for right label */
}

.active {
  color: #3498db; /* Color when label is active (based on isAnimationMode) */
}
</style>
