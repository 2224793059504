<template>
  <div>
    <NavBar />
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
    <div class="edit-employee-container">
      <button class="onetimecard-button" @click="oneTimeCard">
        One Time Card
      </button>
      <span class="delete-icon" @click="deleteEmployee">
        <i class="fas fa-trash-alt"></i>
      </span>

      <h1>Edit Employee</h1>

      <div class="flex-container">
        <div class="personalInfo">
          <div class="form-field">
            <label>*First Name:</label>
            <input class="inputfield" v-model="editedEmployee.firstName" placeholder="Jan" required />
          </div>
          <div class="form-field">
            <label>*Last Name:</label>
            <input class="inputfield" v-model="editedEmployee.lastName" placeholder="Peters" required />
          </div>
          <div class="form-field">
            <label>*BirthDay:</label>
            <input class="inputfield" type="date" v-model="formattedBirthDay" @change="updateBirthDay" required />
          </div>
          <div class="form-field">
            <label>*Address:</label>
            <input class="inputfield" v-model="editedEmployee.address" placeholder="Kerklaan 41, 3600 Genk" required/>
          </div>
        </div>

        <div class="personalizationInfo">
          <div class="form-field">
            <label>*Animation:</label>
            <div class="custom-dropdown" @click="toggleDropdown">
              {{ selectedAnimation ? selectedAnimation.name : 'Select an animation' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="animation in animationList" :key="animation.id" @click.stop="selectAnimation(animation)">
                  {{ animation.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <label>*Hobby:</label>
            <input class="inputfield" v-model="editedPersonalization.hobby" placeholder="Voetbal" required/>
          </div>



          <div class="form-field">
            <label>*Text:</label>
            <div>
              <label>
                <input type="radio" v-model="selectedOption" value="companyDefault"> Company Default Text
              </label>
              <label>
                <input type="radio" v-model="selectedOption" value="writePersonal"> Write Personal Text
              </label>
              <label>
                <input type="radio" v-model="selectedOption" value="suggestions"> Suggestions
              </label>
            </div>
          </div>

          <div class="form-field" v-if="selectedOption === 'companyDefault'">
            <div>{{ companyDefaultText }}</div>
            <!-- Update the personalization text when the radio button is selected -->
            <input type="hidden" :value="companyDefaultText"/>
          </div>

          <div class="form-field" v-if="selectedOption === 'suggestions'">
            <div class="custom-dropdown" @click="toggleDropdown">
              {{ selectedSuggestion ? selectedSuggestion.text : 'Select a suggestion' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="suggestion in suggestionList" :key="suggestion.id" @click.stop="selectSuggestion(suggestion)">
                  {{ suggestion.text }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-field" v-if="selectedOption === 'writePersonal'">
            <div class="text-input-container">
              <input class="inputfield textinputfield" v-model="defaultText"
                     placeholder="Happy birthday from everyone!" :maxlength="maxTextLength"/>
              <span class="character-count">{{ defaultText.length }}/{{ maxTextLength }}</span>
            </div>
            <!-- Update the personalization text when the radio button is selected -->
            <input type="hidden" :value="defaultText"/>
          </div>


          <div class="form-field">
            <label>*Card:</label>
            <div class="custom-dropdown" @click="toggleDropdown">
              <img v-if="selectedCard" :src="selectedCard.imageUrl" alt="Card Image" class="card-image"/>
              {{ selectedCard ? selectedCard.title : '' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="card in cardList" :key="card.id" @click.stop="selectCard(card)">
                  <img :src="card.imageUrl" alt="Card Image" class="card-image select-card-image"/>
                  <p class="selectTitle">{{ card.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input type="button" class="save-button" @click="saveChanges" value="Save Changes" />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "EmployeeEditPage",
  components: {
    NavBar,
  },
  data() {
    return {
      editedEmployee: {
        id: "",
        firstName: "",
        lastName: "",
        birthDay: "",
        address: "",
      },
      editedPersonalization: {
        id: "",
        animation: "",
        hobby: "",
        text: "",
        card: "",
        employeeId: "",
        age: "",
      },
      formattedBirthDay: "",
      cardList: [],
      selectedAnimation: null,
      dropdownVisible: false,
      maxTextLength: 50,
      animationList: [],
      selectedCard: {},
      selectedOption: 'writePersonal',
      companyDefaultText: '',
      selectedSuggestion: null,
      selectedSuggestionId: null,
      suggestionList: [
        // You can fetch this list from an API endpoint if needed
        { id: 1, text: 'Happy birthday form everyone' },
        { id: 2, text: 'Congratulations!' },
        { id: 3, text: 'Cheers to you!' },
      ],
      defaultText: '',

    };
  },
  async mounted() {
    await this.fetchEmployeeDetails();
    await this.fetchCards();
    await this.fetchAnimations();
    await this.fetchCompanyDefaultText();
    await this.fetchQueryText();
    this.setSelectedCard();

    if (this.editedPersonalization.animation === "00000000-0000-0000-0000-000000000000") {
      this.selectedAnimation = null; // No animation selected
    } else {
      this.setSelectedAnimation();
    }

    if (this.suggestionList.length > 0) {
      this.selectedSuggestionId = this.suggestionList[0].id;
    }
  },
  computed: {
    selectedSuggestionText() {
      const selectedSuggestion = this.suggestionList.find(s => s.id === this.selectedSuggestionId);
      return selectedSuggestion ? selectedSuggestion.text : '';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back one step in the history
    },
    oneTimeCard() {
      router.push(`/employee/onetimecard?employeeId=${this.editedEmployee.id}`);
    },
    fetchEmployeeDetails() {
      return new Promise((resolve, reject) => {
        const employeeData = this.$route.query.employeeData;
        const personalizationData = this.$route.query.personalization

        if (employeeData) {
          this.editedEmployee = JSON.parse(employeeData);
          if(personalizationData){
            this.editedPersonalization = JSON.parse(personalizationData);
          }
          this.setFormattedDate();
          resolve();
        } else {
          const employeeId = this.editedEmployee.id;
          axios
              .get(`/api/employees/${employeeId}`)
              .then((response) => {
                this.editedEmployee = response.data;
                this.setFormattedDate();
                resolve();
              })
              .catch((error) => {
                console.error("Error fetching employee details:", error);
                reject(error);
              });
        }
      });
    },
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cardList = response.data.filter(card => card.category === 'Birthday');

        // Find the selected card in the list and set it as selected
        const selectedCardId = this.editedPersonalization.card;
        this.selectedCard = this.cardList.find(card => card.id === selectedCardId);

        // If no card is selected, set a default card or display a message
        if (this.cardList.length > 0) {
          this.selectedCard = this.cardList[0];
        }
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    selectCard(card) {
      this.selectedCard = card;
      this.editedPersonalization.card = card.id; // Set the selected card ID
      this.dropdownVisible = false;
    },
    selectAnimation(animation) {
      this.selectedAnimation = animation;
      this.editedPersonalization.animation = animation.id;
      this.dropdownVisible = false;
    },
    setSelectedCard() {
      // Find the selected card in the list and set it as selected
      const selectedCardId = this.editedPersonalization.card;
      this.selectedCard = this.cardList.find(card => card.id === selectedCardId);

      // If no card is selected, set a default card or display a message
      if (!this.selectedCard && this.cardList.length > 0) {
        this.selectedCard = this.cardList[0];
      }
    },
    setSelectedAnimation() {
      const selectedAnimationId = this.editedPersonalization.animation;

      // Find the selected animation in the list and set it as selected
      this.selectedAnimation = this.animationList.find(animation => animation.id === selectedAnimationId);

      // If no animation is selected or not found, set a default animation or display a message
      if (!this.selectedAnimation && this.animationList.length > 0) {
        this.selectedAnimation = this.animationList[0];
      }
    },
    selectSuggestion(suggestion) {
      this.selectedSuggestion = suggestion;
      this.selectedSuggestionId = suggestion.id;
      this.dropdownVisible = false;
    },
    async fetchAnimations() {
      try {
        const response = await axios.get("/api/animation");
        this.animationList = response.data;
      } catch (error) {
        console.error("Error fetching animations:", error);
      }
    },
    saveChanges() {
      if (
          !this.editedEmployee.firstName ||
          !this.editedEmployee.lastName ||
          !this.editedEmployee.birthDay ||
          !this.editedEmployee.address ||
          !this.editedPersonalization.animation ||
          !this.editedPersonalization.hobby ||
          !this.editedPersonalization.card
      ) {
        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }

      // Parse the updated birth date to compare it with the current date
      const updatedBirthDate = new Date(this.editedEmployee.birthDay);
      const currentDate = new Date();
      const invalideDate = new Date(1900, 1, 1);

      if (updatedBirthDate >= currentDate) {
        // Show an error message if the input date is in the past
        Swal.fire({
          icon: 'error',
          title: 'Invalid Date',
          text: 'BirthDay cannot be in the future.',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      } else if (updatedBirthDate < invalideDate) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Date',
          text: 'BirthDay cannot be before 1900',
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        // Set the personalization text based on the selected radio button option
        if (this.selectedOption === 'writePersonal') {
          this.editedPersonalization.text = this.defaultText;
        } else if (this.selectedOption === 'companyDefault') {
          this.editedPersonalization.text = this.companyDefaultText;
        } else if (this.selectedOption === 'suggestions') {
          // You may need to handle the suggestion text based on your application logic
          this.editedPersonalization.text = this.selectedSuggestionText;
        }

        // Calculate age before making the API call
        this.editedPersonalization.age = new Date().getFullYear() - new Date(this.editedEmployee.birthDay).getFullYear();

        // Continue with the employee update process if the date is valid
        axios
            .put(`/api/employees/`, this.editedEmployee)
            .then(() => {
              // Handle successful response, if needed
              this.editedPersonalization.employeeId = this.editedEmployee.id;

              if (this.editedPersonalization.id) {
                axios
                    .put(`/api/personalisation/`, this.editedPersonalization)
                    .then(() => {
                      Swal.fire({
                        icon: 'success',
                        title: 'Employee successfully edited',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    });
              } else {
                axios
                    .post("/api/personalisation", this.editedPersonalization)
                    .then(() => {
                      Swal.fire({
                        icon: 'success',
                        title: 'Employee successfully edited',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    });
              }
              router.push('/company/employees');
            })
            .catch((error) => {
              // Handle error
              Swal.fire({
                icon: 'error',
                title: 'Error editing employee',
                showConfirmButton: false,
                timer: 1500,
              });
              console.error("Error updating employee:", error);
            });
      }
    },

    updateBirthDay() {
      // Update the editedEmployee's birthDay based on the formattedBirthDay
      this.editedEmployee.birthDay = this.formattedBirthDay;
    },
    deleteEmployee() {
      const employeeId = this.editedEmployee.id;
      const personalisationId = this.editedPersonalization.id;

      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this employee!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3498db',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Get one-time card IDs associated with the employee
          axios.get(`/api/onetimecard/GetOneTimeCardByEmpoyeeId/${employeeId}`)
              .then((cardResponse) => {
                const cardIds = cardResponse.data.map(card => card.id);

                // Delete each one-time card
                const deleteCardPromises = cardIds.map(cardId => {
                  return axios.delete(`/api/onetimecard?id=${cardId}`);
                });

                // Wait for all one-time cards to be deleted
                Promise.all(deleteCardPromises)
                    .then(() => {
                      // Delete personalization
                      axios.delete(`/api/personalisation?id=${personalisationId}`)
                          .then(() => {
                            // Delete employee after personalization and one-time cards are deleted
                            axios.delete(`/api/employees?id=${employeeId}`)
                                .then((employeeResponse) => {
                                  console.log("Employee deleted successfully:", employeeResponse.data);
                                  Swal.fire({
                                    icon: 'success',
                                    title: 'Employee deleted',
                                    showConfirmButton: false,
                                    timer: 1500
                                  });
                                  router.push('/company/employees');
                                })
                                .catch((employeeError) => {
                                  // Handle error deleting employee
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Error deleting employee',
                                    showConfirmButton: false,
                                    timer: 1500
                                  });
                                  console.error("Error deleting employee:", employeeError);
                                });
                          })
                          .catch((personalizationError) => {
                            // Handle error deleting personalization
                            Swal.fire({
                              icon: 'error',
                              title: 'Error deleting personalization',
                              showConfirmButton: false,
                              timer: 1500
                            });
                            console.error("Error deleting personalization:", personalizationError);
                          });
                    })
                    .catch((cardError) => {
                      // Handle error deleting one-time cards
                      Swal.fire({
                        icon: 'error',
                        title: 'Error deleting one-time cards',
                        showConfirmButton: false,
                        timer: 1500
                      });
                      console.error("Error deleting one-time cards:", cardError);
                    });
              })
              .catch((error) => {
                // Check if the error is due to no one-time cards, proceed to delete personalization and employee
                if (error.response && error.response.status === 404) {
                  // No one-time cards, proceed to delete personalization and employee
                  // Delete personalization
                  axios.delete(`/api/personalisation?id=${personalisationId}`)
                      .then(() => {
                        // Delete employee after personalization is deleted
                        axios.delete(`/api/employees?id=${employeeId}`)
                            .then((employeeResponse) => {
                              console.log("Employee deleted successfully:", employeeResponse.data);
                              Swal.fire({
                                icon: 'success',
                                title: 'Employee deleted',
                                showConfirmButton: false,
                                timer: 1500
                              });
                              router.push('/company/employees');
                            })
                            .catch((employeeError) => {
                              // Handle error deleting employee
                              Swal.fire({
                                icon: 'error',
                                title: 'Error deleting employee',
                                showConfirmButton: false,
                                timer: 1500
                              });
                              console.error("Error deleting employee:", employeeError);
                            });
                      })
                      .catch((personalizationError) => {
                        // Handle error deleting personalization
                        Swal.fire({
                          icon: 'error',
                          title: 'Error deleting personalization',
                          showConfirmButton: false,
                          timer: 1500
                        });
                        console.error("Error deleting personalization:", personalizationError);
                      });
                } else {
                  // Handle other errors
                  Swal.fire({
                    icon: 'error',
                    title: 'Error getting one-time card IDs',
                    showConfirmButton: false,
                    timer: 1500
                  });
                  console.error("Error getting one-time card IDs:", error);
                }
              });
        }
      });
    },
    setFormattedDate() {
      // Format the date to "dd/MM/yyyy" based on the user's local timezone in Belgium
      const parsedDate = new Date(this.editedEmployee.birthDay);
      parsedDate.toLocaleDateString('nl-BE', { year: 'numeric', month: '2-digit', day: '2-digit' });
      this.formattedBirthDay = this.formatDateForInput(parsedDate);
    },
    formatDateForInput(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    async fetchCompanyDefaultText() {
      try {
        const companyId = localStorage.getItem('companyId');
        const response = await axios.get(`/api/companies/${companyId}`);
        this.companyDefaultText = response.data.defaultText;
      } catch (error) {
        console.error("Error fetching company default text:", error);
      }
    },
    async fetchQueryText() {
      // Check if the query parameter 'personalization' exists
      const personalizationData = this.$route.query.personalization;

      // If it exists, parse the JSON data
      if (personalizationData) {
        const personalization = JSON.parse(personalizationData);

        // Check if the selected option is 'writePersonal'
        if (this.selectedOption === 'writePersonal') {
          // Update both 'editedPersonalization.text' and 'defaultText'
          this.editedPersonalization.text = personalization.text;
          this.defaultText = personalization.text;  // Add this line
        }
      }
    },
  },
  watch: {
    selectedOption(newOption) {
      if (newOption === 'companyDefault') {
        // Update text with company default text
        this.editedPersonalization.text = this.companyDefaultText;
      } else if (newOption === 'writePersonal') {
        // Update text with the value of the input box
        // (assuming you want to keep the previously entered text if the user switches back and forth)
        this.editedPersonalization.text = this.editedPersonalization.text || this.defaultText;
      } else if (newOption === 'suggestions') {
        // Set the selectedSuggestionId to the ID of the first suggestion
        this.selectedSuggestionId = this.suggestionList.length > 0 ? this.suggestionList[0].id : null;
      }
    },
    selectedSuggestionId(newSuggestionId) {
      const selectedSuggestion = this.suggestionList.find(s => s.id === newSuggestionId);
      this.selectedSuggestion = selectedSuggestion;

      // Add this line to update the text when a suggestion is selected
      this.editedPersonalization.text = this.selectedSuggestionText;
      // Do additional processing if needed
    },
  },
};
</script>
<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

.edit-employee-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 60vw;
  margin: 50px auto;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.personalInfo, .personalizationInfo {
  width: 48%;
  align-items: center;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 60%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  margin-left: 20%;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

.delete-icon {
     cursor: pointer;
     position: absolute;
     top: 12%;
     right: 20%;
     color: red;
     font-size: 20px;
}

.onetimecard-button {
  text-decoration: none;
  position: absolute;
  top: 12%;
  left: 20%;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.form-field {
  position: relative;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 60%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  margin-left: 20%;
  cursor: pointer;
  background-color: white;
}

.custom-dropdown:hover {
  background-color: #ecf0f1;
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 60%;
  overflow: auto;
  max-height: 500px;
  top: 100%;
  margin-left: -11px;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content img {
  transform: none;
  margin-left: 0;
  transition: none;
}

.dropdown-content div:hover img {
  position: fixed;
  top: 53%;
  left: 49.2%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
}


.dropdown-content div:hover p {
  margin-left: 13%;
}

.dropdown-content div p {
  margin-left: 0;
  text-align: center;
}

.dropdown-content div:hover {
  background-color: #ecf0f1;
}

/* Show dropdown content on hover */
.custom-dropdown:hover .dropdown-content {
  display: block;
}

.selectTitle {
  margin-left: 30%;
}

.text-input-container {
  position: relative;
}

.character-count {
  position: absolute;
  top: 15px;
  right: 70px;
  color: #3498db;
  font-size: 0.8em;
}
.textinputfield{
  margin-left: 0;
}

.custom-dropdown::after {
  content: '\25BC'; /* Unicode for a right-pointing triangle */
  font-size: 0.8em;
  margin-left: auto;
  margin-right: 10px;
  color: #3498db; /* Color of the arrow */
}

form-field[selectedOption='suggestions'] .custom-dropdown {
  border: 1px solid #3498db;
}

.form-field[selectedOption='suggestions'] .custom-dropdown:hover {
  background-color: #ecf0f1;
}

.form-field[selectedOption='suggestions'] .dropdown-content {
  border-radius: 5px;
}

.form-field[selectedOption='suggestions'] .dropdown-content div:hover {
  background-color: #ecf0f1;
}

/* Add these styles to align the Suggestions dropdown arrow with other dropdowns */
.form-field[selectedOption='suggestions'] .custom-dropdown::after {
  color: #3498db;
}

@media (max-width: 1000px) {
  .edit-employee-container {
    width: 50vw;
  }
  .inputfield{
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .edit-employee-container {
    width: 80vw;
  }
  .inputfield{
    width: 60vw;
  }
}
</style>
