<template>
  <div class="toggle-container">
    <CardDetailPage v-if="isEditMode"></CardDetailPage>
    <CardEditPage v-else></CardEditPage>
    <label class="toggle-switch">
      <p class="preview">Preview</p>
      <input type="checkbox" v-model="isEditMode"/>
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
import CardEditPage from "@/components/CardEditPage";
import CardDetailPage from "@/components/CardDetailPage";

export default {
  name: "CardEditOrDetailPage",
  components: {CardDetailPage, CardEditPage},
  data() {
    return {
      isEditMode: false,
    };
  },
};
</script>

<style scoped>
.toggle-container {
  position: relative;
}

.toggle-switch {
  position: absolute;
  top: 10vh;
  right: 20px;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3498db;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3498db;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.preview{
  margin-top: -2.5vh;
}
</style>
