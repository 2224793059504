<template>
  <NavBar />
  <div>
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
    <div class="top-right">
      <input
          type="button"
          class="add-card-button"
          @click="addOneTimeCard"
          value="Add one-time card"
      />
    </div>
    <div class="card-layout">
      <div class="edit-employee-container">
        <!-- Add your card layout here -->
        <div class="card-container">
          <h1>One-Time Cards</h1>
          <div class="category-div" v-for="category in categories" :key="category">
            <h3>{{ category }}</h3>
            <router-link v-for="card in getCardsByCategory(category)" :key="card.id" class="card" :to="getCardLink(card)">
              <div class="card-item">
                <i class="fas fa-info-circle icon"></i>
                <h5>{{ card.text }}</h5>
                <img v-if="card.imageUrl" :src="card.imageUrl" alt="Card Image" class="card-image"/>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "OneTimeCardPage",
  components: {
    NavBar,
  },
  data() {
    return {
      employeeId: null,
      oneTimeCards: [],
      categories: ["Wedding", "Birth Child", "Employed Jubilee", "Retirement"],
    };
  },
  mounted() {
    // Retrieve employeeId from the URL
    this.employeeId = this.$route.query.employeeId;

    // Make Axios request to fetch one-time cards
    axios
        .get(`/api/onetimecard/GetOneTimeCardByEmpoyeeId/${this.employeeId}`)
        .then((response) => {
          // Update component data with fetched one-time cards
          this.oneTimeCards = response.data;

          // Fetch additional card details for each one-time card
          this.fetchCardDetails();
        })
        .catch((error) => {
          console.error("Error fetching one-time cards:", error);
        });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getCardsByCategory(category) {
      if (!Array.isArray(this.oneTimeCards)) {
        console.error("oneTimeCards is not an array");
        return [];
      }
      return this.oneTimeCards.filter((card) => card.category === category);
    },
    getCardLink(card) {
      return `/employee/onetimecard/edit?cardId=${card.id}&category=${card.category}&employeeId=${this.employeeId}`;
    },
      addOneTimeCard() {
      router.push(`/employee/onetimecard/add?employeeId=${this.employeeId}`);
    },
    fetchCardDetails() {
      // Fetch card details for each one-time card
      this.oneTimeCards.forEach(async (card) => {
        try {
          const cardDetails = await axios.get(`/api/card/${card.card}`);
          // Update the one-time card with imageUrl
          if (cardDetails.data && cardDetails.data.imageUrl) {
            card.imageUrl = cardDetails.data.imageUrl;
          }
        } catch (error) {
          console.error("Error fetching card details:", error);
        }
      });
    },
  },
};
</script>
<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}
.card-layout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 40vw;
  margin: 50px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

h3{
  margin-bottom: 0;
}

h5{
  margin-top: 10px;
  margin-bottom: 10px;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 75px 20px;
}

.add-card-button {
  margin-bottom: 20px;
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.add-card-button:hover {
  background-color: #2980b9;
}

.card-image{
  width: 5vw;
}

.category-div{
  margin-bottom: 20px;
}

.icon {
  position: absolute;
  color: #3498db; /* Change the color as needed */
  font-size: 20px; /* Adjust the font size as needed */
  visibility: hidden; /* Initially hide the icon */
  margin-left: 83px;
  margin-top: -7px;
}

.card{
  text-decoration: none;
  color: black;
}

.card-item{
  padding: 12px;
}

.card-item:hover {
  border-style: solid;
  border-width: 2px;
  border-color: #3498db;
  padding: 10px;
  width: 10vw;
}

.card-item:hover .icon {
  visibility: visible; /* Show the icon when the card is being hovered */
}
</style>
