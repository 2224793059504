<template>
  <NavBar/>
  <div>
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
  </div>
  <div class="card-layout">
    <div class="add-card-container">
      <!-- Add your card layout here -->
      <div class="card-container">
        <h1>Add One-Time Card</h1>
        <div class="radiobuttonDiv">
          <input type="radio" name="cardType" id="wedding" value="Wedding" v-model="selectedCategory" checked/>
          <label class="radiobutton" for="wedding">Wedding</label>

          <input type="radio" name="cardType" id="birthChild" value="Birth Child" v-model="selectedCategory"/>
          <label class="radiobutton" for="birthChild">Birth child</label>

          <input type="radio" name="cardType" id="employedJubilee" value="Employed Jubilee" v-model="selectedCategory"/>
          <label class="radiobutton" for="employedJubilee">Employed jubilee</label>

          <input type="radio" name="cardType" id="retirement" value="Retirement" v-model="selectedCategory"/>
          <label class="radiobutton" for="retirement">Retirement</label>
        </div>
        <div class="form-field">
          <label>*Animation:</label>
          <div class="custom-dropdown" @click="toggleDropdown">
            {{ selectedAnimation ? selectedAnimation.name : 'Select an animation' }}
            <div class="dropdown-content" v-show="dropdownVisible">
              <div v-for="animation in animationList" :key="animation.id" @click.stop="selectAnimation(animation)">
                {{ animation.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <label>*Date:</label>
          <input class="inputfield" type="date" v-model="formattedBirthDay" @change="updateBirthDay" required/>
        </div>
        <div class="form-field">
          <div class="text-input-container">
            <label>*Text:</label>
            <input class="inputfield textinputfield" v-model="text"
                   placeholder="Congrats" :maxlength="maxTextLength" required/>
            <span class="character-count">{{ text.length }}/{{ maxTextLength }}</span>
          </div>
        </div>
        <div class="form-field">
          <label>*Card:</label>
          <div class="custom-dropdown" @click="toggleDropdown">
            <img v-if="selectedCard" :src="selectedCard.imageUrl" alt="Card Image" class="card-image"/>
            {{ selectedCard ? selectedCard.title : 'Select a card' }}
            <div class="dropdown-content" v-show="dropdownVisible">
              <div v-for="card in cardList" :key="card.id" @click.stop="selectCard(card)">
                <img :src="card.imageUrl" alt="Card Image" class="card-image select-card-image"/>
                <p class="selectTitle">{{ card.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <input type="button" class="save-button" @click="saveChanges" value="Add card"/>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "OneTimeCardAddPage",
  components: {
    NavBar,
  },
  watch: {
    // Watch for changes in selectedCategory and fetch new cards accordingly
    selectedCategory: 'handleSelectedCategoryChange',
  },
  data() {
    return {
      selectedCategory: "Wedding", // Default selected category
      selectedAnimation: null,
      formattedBirthDay: "", // Corrected variable name
      dropdownVisible: false,
      maxTextLength: 50,
      employeeId: null,
      text: "",
      selectedCard: null,
    }
  },
  async mounted() {
    // Retrieve employeeId from the URL
    this.employeeId = this.$route.query.employeeId;
    await this.fetchCards();
    await this.fetchAnimations();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    saveChanges() {
      if (
          !this.text ||
          !this.selectedCard ||
          !this.formattedBirthDay ||
          !this.selectedAnimation
      ) {
        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }


      const oneTimeCardData = {
          text: this.text,
          card: this.selectedCard.id,
          employeeId: this.employeeId, // You need to set the actual employeeId,
          animation: this.selectedAnimation.id,
          date: this.formattedBirthDay,
          category: this.selectedCategory, // Include selected category in the API request
      };

      // Make API request to save one-time card
      axios
          .post("/api/onetimecard", oneTimeCardData)
          .then((response) => {
            // Handle success
            console.log("One-time card saved:", response.data);
            Swal.fire({
              icon: 'success',
              title: 'One-Time card added successfully',
              showConfirmButton: false,
              timer: 1500
            });
            router.push(`/employee/onetimecard?employeeId=${this.employeeId}`);
          })
          .catch((error) => {
            // Handle error
            console.error("Error saving one-time card:", error);
          });

    },
    selectCard(card) {
      this.selectedCard = card;
      this.card = card.id; // Set the selected card ID
      this.dropdownVisible = false;
    },
    selectAnimation(animation) {
      this.selectedAnimation = animation;
      this.animation = animation.id;
      this.dropdownVisible = false;
    },
    async handleSelectedCategoryChange() {
      this.selectedCard = null;
      await this.fetchCards();
    },
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cardList = response.data.filter(card => card.category === this.selectedCategory);
        const defaultCardId = localStorage.getItem('defaultCardId');
        if (defaultCardId) {
          const defaultCard = this.cardList.find(card => card.id === defaultCardId);

          if (defaultCard) {
            this.selectedCard = defaultCard;
          }
        }
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    async fetchAnimations() {
      try {
        const response = await axios.get("/api/animation");
        this.animationList = response.data;
      } catch (error) {
        console.error("Error fetching animations:", error);
      }
    },
  },
};
</script>

<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

.card-layout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 40vw;
  margin: 50px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.form-field {
  margin: 15px 0;
  position: relative;
  width: 30%;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  cursor: pointer;
  background-color: white;
  font-size: 0.90em;
}

.custom-dropdown:hover {
  background-color: #ecf0f1;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  overflow: auto;
  max-height: 500px;
  top: 100%;
  margin-left: -11px;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content img {
  transform: none;
  margin-left: 0;
  transition: none;
}

.dropdown-content div:hover img {
  position: fixed;
  top: 53%;
  left: 36.2%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
}

.dropdown-content div:hover p {
  margin-left: 13%;
}

.dropdown-content div p {
  margin-left: 0;
  text-align: center;
}

.dropdown-content div:hover {
  background-color: #ecf0f1;
}

/* Show dropdown content on hover */
.custom-dropdown:hover .dropdown-content {
  display: block;
}

.selectTitle {
  margin-left: 30%;
}
.custom-dropdown::after {
  content: '\25BC'; /* Unicode for a right-pointing triangle */
  font-size: 0.8em;
  margin-left: auto;
  margin-right: 10px;
  color: #3498db; /* Color of the arrow */
}

.radiobutton {
  margin-right: 20px;
}

.radiobuttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
}

.character-count {
  position: absolute;
  top: 15px;
  color: #3498db;
  font-size: 0.8em;
  margin-top: 20px;
}

.text-input-container {
  position: relative;
}

.textinputfield {
  margin-left: 0;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

@media (max-width: 1000px) {
  .add-card-container {
    width: 50vw;
  }

  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .add-card-container {
    width: 80vw;
  }

  .inputfield {
    width: 60vw;
  }
}
</style>
