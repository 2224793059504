<template>
  <div>
    <NavBar/>
    <div class="add-employee-container form-container">
      <h1>Add Employee</h1>
      <div class="top-left">
        <button class="back-button" @click="goBack">
          Back
        </button>
      </div>

      <div class="flex-container">
        <div class="personalInfo">
          <div class="form-field">
            <label>*First Name:</label>
            <input class="inputfield" v-model="newEmployee.firstName" placeholder="Jan" required/>
          </div>
          <div class="form-field">
            <label>*Last Name:</label>
            <input class="inputfield" v-model="newEmployee.lastName" placeholder="Peters" required/>
          </div>
          <div class="form-field">
            <label>*BirthDay:</label>
            <input class="inputfield" type="date" v-model="newEmployee.birthDay" required/>
          </div>
          <div class="form-field">
            <label>*Address:</label>
            <input class="inputfield" v-model="newEmployee.address" placeholder="Kerklaan 41, 3800 Genk" required/>
          </div>
        </div>

        <div class="personalizationInfo">
          <div class="form-field">
            <label>*Animation:</label>
            <div class="custom-dropdown" @click="toggleDropdown">
              {{ selectedAnimation ? selectedAnimation.name : 'Select an animation' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="animation in animationList" :key="animation.id" @click.stop="selectAnimation(animation)">
                  {{ animation.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <label>*Hobby:</label>
            <input class="inputfield" v-model="newEmployee.personalization.hobby" placeholder="Voetbal" required/>
          </div>

          <div class="form-field">
            <label>*Text:</label>
            <div>
              <label>
                <input type="radio" v-model="selectedOption" value="companyDefault"> Company Default Text
              </label>
              <label>
                <input type="radio" v-model="selectedOption" value="writePersonal"> Write Personal Text
              </label>
              <label>
                <input type="radio" v-model="selectedOption" value="suggestions"> Suggestions
              </label>
            </div>
          </div>


          <div class="form-field" v-if="selectedOption === 'companyDefault'">
            <div>{{ companyDefaultText }}</div>
            <input type="hidden" name="companyDefaultText" :value="companyDefaultText"/>
          </div>

          <div class="form-field" v-if="selectedOption === 'suggestions'">
            <div class="custom-dropdown" @click="toggleDropdown">
              {{ selectedSuggestion ? selectedSuggestion.text : 'Select a suggestion' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="suggestion in suggestionList" :key="suggestion.id" @click.stop="selectSuggestion(suggestion)">
                  {{ suggestion.text }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-field" v-if="selectedOption === 'writePersonal'">
            <div class="text-input-container">
              <input class="inputfield textinputfield" v-model="defaultText"
                     placeholder="Happy birthday from everyone!" :maxlength="maxTextLength"/>
              <span class="character-count">{{ defaultText.length }}/{{ maxTextLength }}</span>
            </div>
            <!-- Update the personalization text when the radio button is selected -->
            <input type="hidden" :value="defaultText"/>
          </div>
          <div class="form-field">
            <label>*Card:</label>
            <div class="custom-dropdown" @click="toggleDropdown">
              <img v-if="selectedCard" :src="selectedCard.imageUrl" alt="Card Image" class="card-image"/>
              {{ selectedCard ? selectedCard.title : '' }}
              <div class="dropdown-content" v-show="dropdownVisible">
                <div v-for="card in cardList" :key="card.id" @click.stop="selectCard(card)">
                  <img :src="card.imageUrl" alt="Card Image" class="card-image select-card-image"/>
                  <p class="selectTitle">{{ card.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input type="button" class="save-button" @click="saveEmployee" value="Save Employee"/>
    </div>

    <div class="csv-upload-container form-container">
      <h1>Upload CSV</h1>
      <input type="button" class="download-button" value="Download CSV Template" @click="downloadCsvTemplate" />

      <div class="form-field">
        <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv"/>
      </div>
      <input type="button" class="save-button" @click="uploadCsv" value="Upload CSV" accept=".csv"/>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "AddEmployeePage",
  components: {
    NavBar,
  },
  data() {
    return {
      selectedCard: {},
      selectedOption: 'companyDefault',
      companyDefaultText: '',
      selectedSuggestion: null,
      selectedSuggestionId: null,
      suggestionList: [
        // You can fetch this list from an API endpoint if needed
        { id: 1, text: 'Happy birthday form everyone' },
        { id: 2, text: 'Congratulations!' },
        { id: 3, text: 'Cheers to you!' },
      ],
      dropdownVisible: false,
      newEmployee: {
        firstName: "",
        lastName: "",
        birthDay: "",
        address: "",
        personalization: {
          animation: "",
          hobby: "",
          text: "",
          card: "",
          employeeId: "",
          age: "",
        },
      },
      animationList: [],
      csvFile: null,
      cardList: [],
      defaultText: '',
      maxTextLength: 50
    };
  },
  computed: {
    selectedSuggestionText() {
      const selectedSuggestion = this.suggestionList.find(s => s.id === this.selectedSuggestionId);
      return selectedSuggestion ? selectedSuggestion.text : '';
    },
  },
  async mounted() {
    await this.fetchCards();
    await this.fetchAnimations();
    await this.fetchCompanyDefaultText();
    this.newEmployee.personalization.text = this.companyDefaultText;
    if (this.suggestionList.length > 0) {
      this.selectedSuggestionId = this.suggestionList[0].id;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back one step in the history
    },
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cardList = response.data.filter(card => card.category === 'Birthday');
        const defaultCardId = localStorage.getItem('defaultCardId');
        if (defaultCardId) {
          const defaultCard = this.cardList.find(card => card.id === defaultCardId);

          if (defaultCard) {
            this.selectedCard = defaultCard;
          }
        }
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    downloadCsvTemplate() {
      // Replace 'your-csv-template-url' with the actual URL for your CSV template
      const csvTemplateUrl = '/EmployeeCSV - Template.csv';

      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.href = csvTemplateUrl;
      anchor.download = 'EmployeeCSV - Template.csv';

      // Simulate a click on the anchor element to trigger the download
      anchor.click();
    },
    selectSuggestion(suggestion) {
      this.selectedSuggestion = suggestion;
      this.selectedSuggestionId = suggestion.id;
      this.dropdownVisible = false;
    },
    async fetchAnimations() {
      try {
        const response = await axios.get("/api/animation");
        this.animationList = response.data;
      } catch (error) {
        console.error("Error fetching animations:", error);
      }
    },
    saveEmployee() {
      if (
          !this.newEmployee.firstName ||
          !this.newEmployee.lastName ||
          !this.newEmployee.birthDay ||
          !this.newEmployee.address ||
          !this.newEmployee.personalization.animation ||
          !this.newEmployee.personalization.hobby
          ) {
        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      const inputDate = new Date(this.newEmployee.birthDay);
      const currentDate = new Date();
      const invalideDate = new Date(1900, 1, 1);

      if (inputDate >= currentDate) {
        // Show an error message if the input date is in the past
        Swal.fire({
          icon: 'error',
          title: 'Invalid Date',
          text: 'BirthDay cannot be in the future.',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      } else if (inputDate < invalideDate) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Date',
          text: 'BirthDay cannot be before 1900',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      } else {
        const companyId = localStorage.getItem("companyId");

        const employeeData = {
          firstName: this.newEmployee.firstName,
          lastName: this.newEmployee.lastName,
          birthDay: this.newEmployee.birthDay,
          address: this.newEmployee.address,
          companyId: companyId,
        };

        // Add personalization data to the employeeData
        employeeData.personalization = {
          animation: this.newEmployee.personalization.animation,
          hobby: this.newEmployee.personalization.hobby,
          text: this.selectedOption === 'writePersonal' ? this.defaultText : this.newEmployee.personalization.text,
          card: this.selectedCard.id,
          age: new Date().getFullYear() - new Date(this.newEmployee.birthDay).getFullYear(),
        };

        console.log("text: " + employeeData.personalization.text)
        console.log("defaulttext: " + this.defaultText)

        axios
            .post("/api/employees", employeeData)
            .then((response) => {
              // Handle successful response, if needed
              // Set the employeeId in personalization data
              this.newEmployee.personalization.employeeId = response.data;

              // If you want to save personalization separately, make another POST request here
              const personalizationData = {
                // personalization data fields
                animation: this.newEmployee.personalization.animation,
                hobby: this.newEmployee.personalization.hobby,
                text: this.selectedOption === 'writePersonal' ? this.defaultText : this.newEmployee.personalization.text,
                card: this.selectedCard.id,
                employeeId: this.newEmployee.personalization.employeeId,
                age: new Date().getFullYear() - new Date(this.newEmployee.birthDay).getFullYear(),
              };

              axios
                  .post("/api/personalisation", personalizationData)
                  .then((personalizationResponse) => {
                    // Handle personalization save success
                    console.log("Personalization data saved:", personalizationResponse.data);
                  })
                  .catch((personalizationError) => {
                    // Handle personalization save error
                    console.error("Error saving personalization data:", personalizationError);
                  });
              Swal.fire({
                icon: 'success',
                title: 'Employee added successfully',
                showConfirmButton: false,
                timer: 1500
              });
              router.push("/company/employees");
            })
            .catch((error) => {
              // Handle error
              Swal.fire({
                icon: 'error',
                title: 'Error adding employee',
                showConfirmButton: false,
                timer: 1500
              });
              console.error("Error adding employee:", error);
            });
      }
    },
    async uploadCsv() {
      const companyId = localStorage.getItem("companyId");
      const defaultCardId = localStorage.getItem('defaultCardId');
      let defaultText = "";

      await axios.get(`/api/companies/${companyId}`)
          .then(response => {
            // Set the company data in the component's state
            defaultText = response.data.defaultText;
          })
          .catch(error => {
            console.error('Error fetching company details:', error);
          });


      const formData = new FormData();
      const fileInput = this.$refs.fileInput;
      if (fileInput.files.length > 0) {
        formData.append("file", fileInput.files[0]);
      }
      formData.append("selectedCardId", defaultCardId);
      formData.append("defaultText", defaultText);


      await axios
          .post(`/api/employees/UploadEmployeesCsv?companyId=${companyId}`, formData)
          .then((response) => {
            // Handle successful response, if needed
            console.log("CSV uploaded successfully:", response.data);
            Swal.fire({
              icon: 'success',
              title: 'CSV uploaded',
              showConfirmButton: false,
              timer: 1500
            });
            router.push("/company/employees");
          })
          .catch((error) => {
            // Handle error
            Swal.fire({
              icon: 'error',
              title: 'Error uploading CSV',
              showConfirmButton: false,
              timer: 1500
            });
            console.error("Error uploading CSV:", error);
          });
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },

    selectCard(card) {
      this.selectedCard = card;
      this.dropdownVisible = false;
      console.log(card.animation)
    },
    selectAnimation(animation) {
      this.selectedAnimation = animation;
      this.newEmployee.personalization.animation = animation.id;
      this.dropdownVisible = false;
    },
    async fetchCompanyDefaultText() {
      try {
        const companyId = localStorage.getItem('companyId');
        const response = await axios.get(`/api/companies/${companyId}`);
        this.companyDefaultText = response.data.defaultText;
      } catch (error) {
        console.error("Error fetching company default text:", error);
      }
    },

    handleSelectedSuggestion() {
      console.log("Selected Suggestion:", this.selectedSuggestion);
      // You can do further processing based on the selected suggestion
    },
  },
  watch: {
    selectedOption(newOption) {
      if (newOption === 'companyDefault') {
        // Update text with company default text
        this.newEmployee.personalization.text = this.companyDefaultText;
      } else if (newOption === 'writePersonal') {
        // Update text with the value of the input box
        this.newEmployee.personalization.text = this.defaultText;
      } else if (newOption === 'suggestions') {
        // Update text with the text of the selected suggestion
        this.newEmployee.personalization.text = this.selectedSuggestionText;
      }
    },
    selectedSuggestionId(newSuggestionId) {
      const selectedSuggestion = this.suggestionList.find(s => s.id === newSuggestionId);
      this.selectedSuggestion = selectedSuggestion;

      // Add this line to update the text when a suggestion is selected
      this.newEmployee.personalization.text = this.selectedSuggestionText;

      // Do additional processing if needed
    },
  },
};
</script>

<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

.add-employee-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 60vw;
  margin: 50px auto;
}

.csv-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 30vw;
  margin: 25px auto;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.personalInfo, .personalizationInfo {
  width: 48%;
  align-items: center;
}

h1 {
  font-size: 2em;
  color: #3498db;
  margin: 0;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;

}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 60%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  margin-left: 20%;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.form-field {
  position: relative;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 60%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  margin-left: 20%;
  cursor: pointer;
  background-color: white;
  font-size: 0.90em;
}

.custom-dropdown:hover {
  background-color: #ecf0f1;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 60%;
  overflow: auto;
  max-height: 500px;
  top: 100%;
  margin-left: -11px;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content img {
  transform: none;
  margin-left: 0;
  transition: none;
}

.dropdown-content div:hover img {
  position: fixed;
  top: 53%;
  left: 49.2%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
}

.dropdown-content div:hover p {
  margin-left: 13%;
}

.dropdown-content div p {
  margin-left: 0;
  text-align: center;
}

.dropdown-content div:hover {
  background-color: #ecf0f1;
}

/* Show dropdown content on hover */
.custom-dropdown:hover .dropdown-content {
  display: block;
}

.selectTitle {
  margin-left: 30%;
}

.text-input-container {
  position: relative;
}

.character-count {
  position: absolute;
  top: 15px;
  right: 70px;
  color: #3498db;
  font-size: 0.8em;
}
.textinputfield{
  margin-left: 0;
}

.custom-dropdown::after {
  content: '\25BC'; /* Unicode for a right-pointing triangle */
  font-size: 0.8em;
  margin-left: auto;
  margin-right: 10px;
  color: #3498db; /* Color of the arrow */
}

.download-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
  margin-bottom: 30px;
  margin-top: 20px;
}

.download-button:hover {
  background-color: #2980b9;
}

@media (max-width: 1000px) {
  .add-employee-container {
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .add-employee-container {
    width: 90vw;
  }
}
</style>
