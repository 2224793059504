<template>
  <div>
    <NavBar />
    <div class="page-container">
      <div class="employee-list-container">
        <h1>Employee List</h1>

        <div class="top-right">
          <input
              type="button"
              class="add-employee-button"
              @click="$router.push({ name: 'addemployee' })"
              value="Add employees"
          />
        </div>

        <div class="search-container" v-if="!sortByMonth">
          <input
              type="text"
              v-model="searchQuery"
              placeholder="Search by name"
              class="search-bar"
          />
        </div>

        <button class="sort-button" @click="sortByBirthday">
          {{ sortButtonText }}
        </button>
        <div  v-if="sortByMonth" class="larger-employee-list-wrapper">
          <div :class="{ 'employee-list-row': filteredEmployees.length > 10 }" class="employee-list">
            <div class="month-grid-container">
              <div v-for="(group, month) in groupedEmployees" :key="month" class="month-container">
                <h2>{{ month.charAt(0).toUpperCase() + month.slice(1) }}</h2>
                <div class="employee-list-row">
                  <div v-for="employee in group" :key="employee.id" class="employee-item">
                    <div class="employee-info">
                      <span>{{ employee.firstName }} {{ employee.lastName }}</span>
                      <input
                          type="button"
                          @click="editEmployee(employee)"
                          value="Edit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="employee-list-wrapper">
          <div :class="{ 'employee-list-row': filteredEmployees.length > 10 }" class="employee-list">
            <div v-for="employee in filteredEmployees" :key="employee.id" class="employee-item">
              <div class="employee-info">
                <span>{{ employee.firstName }} {{ employee.lastName }}</span>
                <input
                    type="button"
                    @click="editEmployee(employee)"
                    value="Edit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";

export default {
  name: "EmployeeListPage",
  components: {
    NavBar,
  },
  data() {
    return {
      employees: [],
      searchQuery: "",
      sortByMonth: localStorage.getItem('sortByMonth') === 'true' || false,
    };
  },
  computed: {
    sortButtonText() {
      return this.sortByMonth ? 'Sort Alphabetically' : 'Sort by Birthday';
    },
    sortedEmployees() {
      // Create a new array before sorting
      const sortedArray = [...this.employees];

      if (this.sortByMonth) {
        // Sort employees by birthday
        return sortedArray.sort((a, b) => {
          const dateA = new Date(a.birthDay);
          const dateB = new Date(b.birthDay);

          // Compare by year and month
          return dateA - dateB;
        });
      } else {
        // Sort employees alphabetically
        return sortedArray.sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
          const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
          return nameA.localeCompare(nameB);
        });
      }
    },
    groupedEmployees() {
      if (this.sortByMonth) {
        // Define the correct order of months
        const monthOrder = [
          'januari', 'februari', 'maart', 'april', 'mei', 'juni',
          'juli', 'augustus', 'september', 'oktober', 'november', 'december'
        ];

        // Group employees by month and sort them based on the month order
        const grouped = {};
        for (const employee of this.sortedEmployees) {
          const monthLabel = new Date(employee.birthDay).toLocaleString('default', { month: 'long' });

          if (!grouped[monthLabel]) {
            grouped[monthLabel] = [];
          }
          grouped[monthLabel].push(employee);
        }

        // Sort the groups based on the month order
        const sortedGroups = Object.keys(grouped).sort((a, b) => {
          return monthOrder.indexOf(a) - monthOrder.indexOf(b);
        });

        // Create a new object with sorted groups
        const result = {};
        sortedGroups.forEach(month => {
          result[month] = grouped[month];
        });

        return result;
      } else {
        // If not sorting by month, return a single group
        return { 'All Employees': this.sortedEmployees };
      }
    },
    filteredEmployees() {
      const query = this.searchQuery.toLowerCase();
      return this.sortedEmployees.filter((employee) => {
        const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
        return fullName.includes(query);
      });
    },
  },
  methods: {
    editEmployee(employee) {
      // Fetch personalization data before navigating to the editemployee page
      this.fetchPersonalizationData(employee.id)
          .then((personalization) => {
            const queryData = {
              employeeData: JSON.stringify(employee),
              personalization: JSON.stringify(personalization),
            };
            this.$router.push({ name: 'editemployee', query: queryData });
          })
          .catch((error) => {
            console.error('Error fetching personalization data:', error);
          });
    },

    fetchPersonalizationData(employeeId) {
      return axios.get(`/api/personalisation/GetPersonalisationByEmpoyeeId/${employeeId}`)
          .then((response) => response.data)
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              // Handle 404 error (do nothing in this example)
              console.log('Personalization data not found for employee:', employeeId);
            } else {
              // Handle other errors
              console.error('Error fetching personalization data:', error);
              throw error;
            }
          });
    },
    fetchEmployeeList() {
      const companyId = localStorage.getItem("companyId");
      axios
          .get(`/api/companies/employeelist?companyId=${companyId}`)
          .then((response) => {
            this.employees = response.data;
          })
          .catch((error) => {
            console.error("Error fetching employee list:", error);
          });
    },
    sortByBirthday() {
      // Toggle the sortByMonth flag
      this.sortByMonth = !this.sortByMonth;

      localStorage.setItem('sortByMonth', this.sortByMonth);


      // If sorting by birthday, handle potential invalid dates
      if (this.sortByMonth) {
        try {
          // Filter out employees with invalid dates and log them
          const employeesWithValidDates = this.employees.filter((employee) => {
            const date = new Date(employee.birthDay);
            if (isNaN(date.getTime())) {
              console.error(`Invalid date for employee ${employee.id}: ${employee.birthDay}`);
              return false;
            }
            return true;
          });

          if (employeesWithValidDates.length === 0) {
            // Show a message to the user and revert to the previous state
            console.error('No employees with valid dates.');
            this.sortByMonth = false;
          } else {
            // Sort employees by birthday
            this.employees = employeesWithValidDates.sort((a, b) => {
              const dateA = new Date(a.birthDay);
              const dateB = new Date(b.birthDay);

              // Compare by year and month
              return dateA - dateB;
            });
          }
        } catch (error) {
          console.error('Error sorting by birthday:', error);
          // Handle the error, e.g., display a message to the user or revert to the previous state
          this.sortByMonth = false;
        }
      }
    },

  },
  mounted() {
    this.fetchEmployeeList();
  },
};
</script>

<style scoped>
.page-container {
  position: relative;
  height: 90vh; /* Set the height of the container to 100% of the viewport height */
  overflow: hidden; /* Hide overflow from the container */
}

.employee-list-wrapper {
  max-height: calc(100vh - 310px); /* Adjust the height as needed, considering other elements on the page */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the height */
}

.larger-employee-list-wrapper {
  max-height: calc(100vh - 255px); /* Adjust the height as needed, considering other elements on the page */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the height */
}

.employee-list-container {
  text-align: center;
  margin: 50px;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.search-container {
  margin-bottom: 20px;
}
input {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.search-bar {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 20vw;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: #f2f2f2;
  color: #000;
}

.search-bar:hover {
  background-color: lightgrey;
}

.employee-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
}

.employee-list-row {
  flex-direction: row; /* Display items in a row */
}

.employee-item {
  background-color: #f8f9fa;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 15vw;
  min-width: 150px;
}

.employee-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-employee-button {
  margin-bottom: 20px;
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.add-employee-button:hover {
  background-color: #2980b9;
}

.sort-button {
  margin-bottom: 20px;
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.sort-button:hover {
  background-color: #2980b9;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

.month-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 20px; /* Adjust the gap between months */
}

@media(max-width: 600px) {
  .employee-list-wrapper{
    width: 100%;
  }
  .search-bar{
    width: 50vw;
  }
}
</style>
