<template>
  <div>
    <NavBar />
    <div class="page-container">
      <div class="card-detail-container">
        <div class="image-container">
          <div class="selectedImageContainer">
            <button class="arrow-button left" @click="changeImage(-1)">
              <i class="fas fa-chevron-left"></i>
            </button>
            <img class="selectedImage" :src="selectedImage" alt="Card Image" @click="showFullImage(selectedImage)" />
            <button class="arrow-button right" @click="changeImage(1)">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
          <div class="thumbnail-container">
            <img v-for="(image, index) in thumbnailImages" :key="index" :src="image" alt="Thumbnail" @click="showFullImage(image, index)" />
          </div>
        </div>
        <div class="details-container">
          <div class="detail-item">
            <h2>{{ card.title }}</h2>
            <p class="productNumber">{{ card.productNumber }}</p>
            <p v-html="formattedDescription"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";

export default {
  name: "CardDetailPage",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      card: {},
      selectedImage: "",
      thumbnailImages: [],
      currentImageIndex: 0,
    };
  },
  computed: {
    formattedDescription() {
      // Check if this.card is defined and has a description property
      if (this.card && this.card.description) {
        // Convert newline characters to HTML line breaks
        return this.card.description.replace(/\n/g, "<br>");
      } else {
        // Return an empty string or another default value if there is no description
        return "";
      }
    },
  },
  mounted() {
    // Fetch data for the selected card
    this.fetchCardDetails();
  },
  methods: {
    async fetchCardDetails() {
      const cardId = this.$route.query.id; // Fetch card ID from the route query parameters
      this.loading = true;
      try {
        const response = await axios.get(`/api/card/${cardId}`);
        this.card = response.data;
        this.selectedImage = this.card.imageUrl;

        // Filter out empty e1-e5 image URLs
        this.thumbnailImages = [
          this.card.imageUrl,
          this.card.e1ImageUrl,
          this.card.e2ImageUrl,
          this.card.e3ImageUrl,
          this.card.e4ImageUrl,
          this.card.e5ImageUrl,
        ].filter(imageUrl => imageUrl !== "");

        this.loading = false;
      } catch (error) {
        console.error("Error fetching card details:", error);
        this.loading = false;
      }
    },
    showFullImage(image, index) {
      this.selectedImage = image;
      this.currentImageIndex = index;
    },
    changeImage(offset) {
      // Change the current image index
      this.currentImageIndex += offset;

      // Ensure the index stays within bounds
      if (this.currentImageIndex < 0) {
        this.currentImageIndex = this.thumbnailImages.length - 1;
      } else if (this.currentImageIndex >= this.thumbnailImages.length) {
        this.currentImageIndex = 0;
      }

      // Update the selected image
      this.selectedImage = this.thumbnailImages[this.currentImageIndex];
    },
  },
};
</script>

<style scoped>
/* Add your styling for the card detail page here */
.card-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 50px;
}

.image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 50vw;
  position: relative;
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333; /* Adjust the color as needed */
}

.arrow-button.left {
  left: 4vw;
}

.arrow-button.right {
  right: 4vw;
}

.arrow-button i {
  margin: 0;
}

.thumbnail-container {
  display: flex;
  margin-top: 10px;
}

.thumbnail-container img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
}

.details-container {
  flex: 1;
}

.detail-item {
  margin-bottom: 10px;
  text-align: left;
  margin-right: 7.5vw;
}

.detail-item label {
  font-weight: bold;
  margin-right: 5px;
}
.selectedImage{
  max-height: 70vh;
  max-width: 45vw;
  align-self: center;
}

.selectedImageContainer{
  height: 70vh;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2{
  margin-bottom: 0;
  color: #3498db;
}

.productNumber{
  margin-top: 0;
}
</style>
