import LoginPage from "@/components/LoginPage";
import RegisterPage from "@/components/RegisterPage";
import HomePage from "@/components/NavBar";
import CompanyPage from "@/components/CompanyPage";
import CompanyEditPage from "@/components/CompanyEditPage";
import EmployeeListPage from "@/components/EmployeeListPage";
import EmployeeAddPage from "@/components/EmployeeAddPage";
import EmployeeEditPage from "@/components/EmployeeEditPage";
import { createWebHistory, createRouter } from "vue-router";
import CompanyAddPage from "@/components/CompanyAddPage";
import EmployeesByMonthPage from "@/components/EmployeesByMonthPage";
import CardListPage from "@/components/CardListPage";
import CardAddPage from "@/components/CardAddPage";
import CardEditPage from "@/components/CardEditPage";
import CardDetailPage from "@/components/CardDetailPage";
import CardEditOrDetailPage from "@/components/CardEditOrDetailPage";
import AnimationListPage from "@/components/AnimationListPage";
import AnimationDetailsPage from "@/components/AnimationDetailsPage";
import AvailableCardsAndAnimationsPage from "@/components/AvailableCardsAndAnimationsPage";
import AnimationAddPage from "@/components/AnimationAddPage";
import OneTimeCardPage from "@/components/OneTimeCardPage.vue";
import OneTimeCardAddPage from "@/components/OneTimeCardAddPage.vue";
import OneTimeCardEditPage from "@/components/OneTimeCardEditPage.vue";

const routes = [
    {
        path: '/',
        component: LoginPage,
        name: 'login'
    },
    {
        path: '/register',
        component: RegisterPage,
        name: 'register'
    },
    {
        path: '/home',
        component: HomePage,
        name: 'home',
        meta: { requiresAuth: true }
    },
    {
        path: '/company',
        component: CompanyPage,
        name: 'company',
        meta: { requiresAuth: true }
    },
    {
        path: '/company/edit',
        component: CompanyEditPage,
        name: 'editcompany',
        meta: { requiresAuth: true }
    },
    {
        path: '/company/add',
        component: CompanyAddPage,
        name: 'addcompany',
        meta: { requiresAuth: true }
    },
    {
        path: '/company/employees',
        component:  EmployeeListPage,
        name: 'employeelist',
        meta: { requiresAuth: true }
    },
    {
        path: '/company/employees/add',
        component: EmployeeAddPage,
        name: 'addemployee',
        meta: { requiresAuth: true }
    },
    {
        path: '/company/employees/edit',
        component: EmployeeEditPage,
        name: 'editemployee',
        meta: { requiresAuth: true }
    },
    {
        path: '/employees/birthdaynextmonth',
        component: EmployeesByMonthPage,
        name: 'birthdayemployee',
        meta: { requiresAuth: true }
    },
    {
        path: '/cards',
        component: CardListPage,
        name: 'cardlist',
        meta: { requiresAuth: true }
    },
    {
        path: '/cards/add',
        component: CardAddPage,
        name: 'addcard',
        meta: { requiresAuth: true }
    },
    {
        path: '/cards/edit',
        component: CardEditPage,
        name: 'editcard',
        meta: { requiresAuth: true }
    },
    {
        path: '/card/details',
        component: CardDetailPage,
        name: 'detailcard',
        meta: { requiresAuth: true }
    },
    {
        path: '/card/editordetail',
        component: CardEditOrDetailPage,
        name: 'editordetailcard',
        meta: { requiresAuth: true }
    },
    {
        path: '/animation',
        component: AnimationListPage,
        name: 'animation',
        meta: { requiresAuth: true }
    },
    {
        path: '/animation/details',
        component: AnimationDetailsPage,
        name: 'animationdetails',
        meta: { requiresAuth: true }
    },
    {
        path: '/cardsandanimations',
        component: AvailableCardsAndAnimationsPage,
        name: 'cardsandanimations',
        meta: { requiresAuth: true }
    },
    {
        path: '/animations/add',
        component: AnimationAddPage,
        name: 'addanimation',
        meta: { requiresAuth: true }
    },
    {
        path: '/employee/onetimecard',
        component: OneTimeCardPage,
        name: 'onetimecard',
        meta: { requiresAuth: true }
    },
    {
        path: '/employee/onetimecard/add',
        component: OneTimeCardAddPage,
        name: 'addonetimecard',
        meta: { requiresAuth: true }
    },
    {
        path: '/employee/onetimecard/edit',
        component: OneTimeCardEditPage,
        name: 'editonetimecard',
        meta: { requiresAuth: true }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('token');
    if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
        next('/');
    } else {
        next();
    }
});


export default router;
