<template>
  <div>
    <NavBar />
    <div class="page-container">
      <div class="card-list-container">
        <div v-if="isTosterAdminTokenValid" class="add-animation-container">
          <router-link :to="{ name: 'addanimation' }">
            <button class="add-animation-button">Add Animation</button>
          </router-link>
        </div>
        <div class="card-list-wrapper">
          <div class="card-container">
            <div
                v-for="animation in animations"
                :key="animation.id"
                class="card animation-card"
                @mouseover="setHoveredAnimation(animation.id)"
                @mouseleave="resetHoveredAnimation()"
            >
              <h3 class="card-title">{{ animation.name }}</h3>
              <iframe
                  :src="animation.url"
                  width="640"
                  height="480"
                  frameborder="0"
                  allow="autoplay; fullscreen; vr"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
              ></iframe>
              <div
                  v-if="hoveredAnimation === animation.id && isTosterAdminTokenValid"
                  class="delete-button"
                  @click="confirmDelete(animation.id)"
              >
                <i class="fas fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AnimationListPage",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      animations: [],
      isTosterAdminTokenValid: false,
      hoveredAnimation: null,
    };
  },
  mounted() {
    // Fetch data from the /api/animation endpoint
    this.fetchAnimations();
    const tosterAdminToken = localStorage.getItem("tosterAdminToken");
    this.isTosterAdminTokenValid =
        tosterAdminToken === process.env.VUE_APP_TOSTER_ADMIN_TOKEN;
  },
  methods: {
    async fetchAnimations() {
      try {
        const response = await axios.get("/api/animation");
        this.animations = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching animations:", error);
        this.loading = false;
      }
    },
    setHoveredAnimation(animationId) {
      this.hoveredAnimation = animationId;
    },
    resetHoveredAnimation() {
      this.hoveredAnimation = null;
    },
    async confirmDelete(animationId) {
      const result = await Swal.fire({
        title: "Delete Animation",
        text: "Are you sure you want to delete this animation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3498db",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        this.deleteAnimation(animationId);
      }
    },
    async deleteAnimation(animationId) {
      try {
        await axios.delete(`/api/animation?id=${animationId}`);
        // Optionally, update the local animations array to reflect the deletion
        this.animations = this.animations.filter(
            (animation) => animation.id !== animationId
        );
        Swal.fire({
          title: "Deleted!",
          text: "The animation has been deleted.",
          showConfirmButton: false,
          icon: "success",
          timer: 1500,
        });
      } catch (error) {
        console.error("Error deleting animation:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while deleting the animation.",
          icon: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.card-list-container {
  text-align: center;
  margin: 0 50px;
}

.add-animation-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
  position: absolute;
  left: auto;
  right: 100px;
  top: 20px;
}

.add-animation-button:hover {
  background-color: #2980b9;
}

.card-list-wrapper {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
  margin-top: 70px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.card {
  flex: 0 1 calc(33% - 1px); /* Increase the percentage for wider cards */
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  box-sizing: border-box;
  list-style-type: none;
  margin-bottom: 3px;
  text-decoration: none;
  transition: border-color 0.3s ease;
  margin-top: 10px;
  position: relative;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  font-size: 16px;
  color: #3498db;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  display: none;
}

.card:hover .delete-button {
  display: block;
}

.delete-button i {
  font-size: 14px;
}
</style>
