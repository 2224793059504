<template>
  <div>
    <NavBar />
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
    <div class="edit-card-container">
      <span class="delete-icon" @click="deleteCard">
        <i class="fas fa-trash-alt"></i>
      </span>

      <h1>Edit Card</h1>
      <form @submit.prevent="saveChanges">
        <div class="extra-images-container">
          <div class="form-field">
            <label for="cardTitle">*Card Title:</label>
            <input class="inputfield doublefield" v-model="editedCard.title" type="text" id="cardTitle" required>
          </div>
          <div class="form-field">
            <label for="cardProcuctNumber">*Product number:</label>
            <input class="inputfield doublefield" v-model="editedCard.productNumber" type="text" id="cardProcuctNumber" required>
          </div>
        </div>
        <div class="form-field">
          <label>*Main image URL:</label>
          <input class="inputfield topinputfield" v-model="editedCard.imageUrl" placeholder="Main Image URL" required />
        </div>
        <div class="form-field">
          <label>Description:</label>
          <textarea class="inputfield description-box" v-model="editedCard.description"></textarea>
        </div>

        <h3>Extra images:</h3>
        <div class="extra-images-container">
          <div class="form-field extra-image">
            <label for="firstCardImageUrl">First extra image URL:</label>
            <input class="inputfield extra-image-input-field" v-model="editedCard.e1ImageUrl" type="text" id="firstCardImageUrl" />
          </div>
          <div class="form-field extra-image">
            <label for="secondCardImageUrl">Second extra image URL:</label>
            <input class="inputfield extra-image-input-field" v-model="editedCard.e2ImageUrl" type="text" id="secondCardImageUrl" />
          </div>
          <div class="form-field extra-image">
            <label for="thirdCardImageUrl">Third extra image URL:</label>
            <input class="inputfield extra-image-input-field" v-model="editedCard.e3ImageUrl" type="text" id="thirdCardImageUrl" />
          </div>
        </div>
        <div class="extra-images-container">
          <div class="form-field extra-image">
            <label for="fourthCardImageUrl">Fourth extra image URL:</label>
            <input class="inputfield extra-image-input-field" v-model="editedCard.e4ImageUrl" type="text" id="fourthCardImageUrl" />
          </div>
          <div class="form-field extra-image">
            <label for="fifthCardImageUrl">Fifth extra image URL:</label>
            <input class="inputfield extra-image-input-field" v-model="editedCard.e5ImageUrl" type="text" id="fifthCardImageUrl" />
          </div>
        </div>
        <div class="form-field">
          <label for="cardCategory">*Category:</label>
          <select class="inputfield topinputfield" v-model="editedCard.category" id="cardCategory" required>
            <option value="">Select a category</option>
            <option value="Birthday">Birthday</option>
            <option value="Wedding">Wedding</option>
            <option value="Birth Child">Birth Child</option>
            <option value="Employed Jubilee">Employed Jubilee</option>
            <option value="Retirement">Retirement</option>
          </select>
        </div>
        <button class="save-button" type="submit">Update</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "CardEditPage",
  components: {
    NavBar,
  },
  data() {
    return {
      editedCard:{
        id: "",
        title: "",
        productNumber: "",
        imageUrl: "",
        description: "",
        e1ImageUrl: "",
        e2ImageUrl: "",
        e3ImageUrl: "",
        e4ImageUrl: "",
        e5ImageUrl: "",
        category:"",
      },
    };
  },
  mounted() {
    // Fetch the card data based on the ID from the route params
    this.fetchCardData();
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back one step in the history
    },
    async fetchCardData() {
      const cardId = this.$route.query.id;
      this.editedCard.id = cardId;
      try {
        const response = await axios.get(`/api/card/${cardId}`);
        const cardData = response.data;

        // Populate the data fields with the card data
        this.editedCard.title = cardData.title;
        this.editedCard.productNumber = cardData.productNumber;
        this.editedCard.imageUrl = cardData.imageUrl;
        this.editedCard.description = cardData.description;
        this.editedCard.e1ImageUrl = cardData.e1ImageUrl || "";
        this.editedCard.e2ImageUrl = cardData.e2ImageUrl || "";
        this.editedCard.e3ImageUrl = cardData.e3ImageUrl || "";
        this.editedCard.e4ImageUrl = cardData.e4ImageUrl || "";
        this.editedCard.e5ImageUrl = cardData.e5ImageUrl || "";
        this.editedCard.category = cardData.category;
      } catch (error) {
        console.error("Error fetching card data:", error);
        // Handle error, e.g., show an error message or redirect
        router.push('/cards');
      }
    },
    async saveChanges() {
      console.log("Edited Card before saving:", this.editedCard);

      // Make a request to retrieve all cards
      try {
        const response = await axios.get('/api/card');
        const existingCard = response.data.find(card => card.productNumber === this.editedCard.productNumber);

        if (existingCard && existingCard.id !== this.editedCard.id) {
          // Display an error message if a card with the same product number already exists
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'A card with the same product number already exists.',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          // Proceed to update the card if no card with the same product number is found
          axios.put(`/api/card/`, this.editedCard)
              .then(() => {
                // Handle success, e.g., show a success message
                Swal.fire({
                  icon: 'success',
                  title: 'Card updated',
                  showConfirmButton: false,
                  timer: 1500,
                });
                router.push('/cards');
              })
              .catch(error => {
                // Handle error, e.g., show an error message
                console.error('Error updating card:', error);
                Swal.fire({
                  icon: 'error',
                  title: 'Error updating card',
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
        }
      } catch (error) {
        // Handle error, e.g., show an error message or redirect
        console.error('Error fetching cards:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while fetching cards.',
        });
      }
    },

    deleteCard() {
      const cardId = this.$route.query.id;

      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this card!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3498db',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete(`/api/card?id=${cardId}`)
              .then(() => {
                console.log("Card deleted successfully");
                Swal.fire({
                  icon: 'success',
                  title: 'Card deleted',
                  showConfirmButton: false,
                  timer: 1500
                });
                router.push('/cards');
              })
              .catch((error) => {
                // Handle error
                console.error("Error deleting card:", error);
                Swal.fire({
                  icon: 'error',
                  title: 'Error deleting card',
                  showConfirmButton: false,
                  timer: 1500
                });
              });
        }
      });
    },
  },
};
</script>

<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}
.edit-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 35vw;
  margin: 30px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

h3{
  color: #3498db;
  margin-top: 50px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 60%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  margin-left: 20%;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

.delete-icon {
  cursor: pointer;
  position: absolute;
  top: 15%;
  right: 33%;
  color: red;
  font-size: 20px;
}

.extra-images-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.extra-image {
  margin-left: 5px;
  margin-right: 5px;
}

.extra-image-input-field{
  width: 100%;
  margin-left: 0;
}

.description-box{
  resize: vertical;
  min-height: 9vh;
  width: 100%;
  margin-left: 0;
}

.topinputfield{
  width: 40%;
  margin-left: 30%;
}

.doublefield{
  width: 92%;
  margin: 0 0 0 4%;
}

@media (max-width: 1000px) {
  .edit-card-container {
    width: 50vw;
  }
  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .edit-card-container {
    width: 80vw;
  }
  .inputfield {
    width: 60vw;
  }
}
</style>
