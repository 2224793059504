<template>
  <div>
    <NavBar />

    <div v-if="company" class="company-details-container">
      <h1>Company Details</h1>
      <div class="company-info">
        <p><strong>Company Name:</strong> {{ company.name }}</p>
        <img style="max-height: 200px" :src="getImageSrc(company.logoUrl)" alt="Company Logo">
        <p><strong>Default Card:</strong> {{ getDefaultCardTitle() }}</p>
        <p><strong>Default Text:</strong> {{ displayDefaultText }}</p>
      </div>

      <input type="button" @click="editCompany" value="Edit Company">
    </div>
    <div v-else class="company-not-found">
      <input type="button" @click="$router.push('/company/add')" value="Create Company">
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";

export default {
  name: "CompanyPage",
  components: {
    NavBar,
  },
  data() {
    return {
      company: null,
      loading: true,
      cardList: [],
    };
  },
  computed: {
    displayDefaultText() {
      // Display a slash ("/") if defaultText is an empty string
      return this.company.defaultText || '/';
    },
  },
  mounted() {
    // Assuming you have a backend endpoint to fetch company details and items
    this.fetchCompanyDetails();
  },
  methods: {
    async fetchCompanyDetails() {
      const companyId = localStorage.getItem('companyId');
      try {
        // Fetch company details
        const companyResponse = await axios.get(`/api/companies/${companyId}`);
        this.company = companyResponse.data;

        // Fetch card list
        const cardResponse = await axios.get("/api/card");
        this.cardList = cardResponse.data;

        // Set default card if available
        if (this.cardList.length > 0) {
          const defaultCardId = this.company.defaultCard;
          this.selectedCard = this.cardList.find(card => card.id === defaultCardId);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    },
    getImageSrc(url) {
      return url;
    },
    getDefaultCardTitle() {
      // Assuming you have a method or API call to get card details by ID
      const cardId = this.company.defaultCard;
      const defaultCard = this.cardList.find(card => card.id === cardId);
      return defaultCard ? defaultCard.title : 'No Default Card';
    },
    editCompany() {
      if (this.company) {
        this.$router.push({
          name: 'editcompany',
          query: {
            companyId: this.company.id,
            companyName: this.company.name,
            logoUrl: this.company.logoUrl,
            defaultCard: this.company.defaultCard,
            defaultText: this.company.defaultText
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.company-details-container {
  text-align: center;
  margin: 50px auto;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 30vw;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.company-info {
  margin-top: 20px;
}

img {
  max-width: 100%;
  height: auto;
  margin-top: 15px;
}

input {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

input:hover {
  background-color: #2980b9;
}

.company-not-found {
  margin-top: 20px;
  color: #e74c3c;
}
@media (max-width: 1000px) {
  .company-details-container {
    width: 50vw;
  }

}

@media (max-width: 600px) {
  .company-details-container {
    width: 80vw;
  }
}

</style>
