<template>
  <NavBar />
  <div>
    <div class="top-left">
      <button class="back-button" @click="goBack">
        Back
      </button>
    </div>
  </div>
  <div class="card-layout">
    <div class="edit-card-container">
      <div class="card-container">
        <span class="delete-icon" @click="deleteOneTimeCard">
        <i class="fas fa-trash-alt"></i>
      </span>
        <h1>Edit One-Time Card</h1>
        <div class="form-field">
          <label>*Animation:</label>
          <div class="custom-dropdown" @click="toggleDropdown">
            {{ selectedAnimation ? selectedAnimation.name : 'Select an animation' }}
            <div class="dropdown-content" v-show="dropdownVisible">
              <div v-for="animation in animationList" :key="animation.id" @click.stop="selectAnimation(animation)">
                {{ animation.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <label>*Date:</label>
          <input class="inputfield" type="date" v-model="formattedBirthDay" @change="updateBirthDay" required />
        </div>
        <div class="form-field">
          <div class="text-input-container">
            <label>*Text:</label>
            <input class="inputfield textinputfield" v-model="text" placeholder="Congrats" :maxlength="maxTextLength" required />
            <span class="character-count">{{ text.length }}/{{ maxTextLength }}</span>
          </div>
        </div>
        <div class="form-field">
          <label>*Card:</label>
          <div class="custom-dropdown" @click="toggleDropdown">
            <img v-if="selectedCard" :src="selectedCard.imageUrl" alt="Card Image" class="card-image" />
            {{ selectedCard ? selectedCard.title : 'Select a card' }}
            <div class="dropdown-content" v-show="dropdownVisible">
              <div v-for="card in cardList" :key="card.id" @click.stop="selectCard(card)">
                <img :src="card.imageUrl" alt="Card Image" class="card-image select-card-image" />
                <p class="selectTitle">{{ card.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <input type="button" class="save-button" @click="saveChanges" value="Save Changes" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
// import Swal from "sweetalert2";
// import router from "@/router";

export default {
  name: "OneTimeCardEditPage",
  components: {
    NavBar,
  },
  data() {
    return {
      selectedAnimation: null,
      formattedBirthDay: "", // Corrected variable name
      dropdownVisible: false,
      maxTextLength: 50,
      text: "",
      selectedCard: null,
      animationList: [], // Define animationList
      cardList: [], // Define cardList
      selectedCategory: "",
    };
  },
  async mounted() {
    // Retrieve cardId from the URL
    const cardId = this.$route.query.cardId;
    this.selectedCategory = this.$route.query.category;

    // Fetch card details using cardId
    await this.fetchCardDetails(cardId);

    // Fetch animations and other necessary data
    await this.fetchAnimations();
    await this.fetchCards();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectCard(card) {
      this.selectedCard = card;
      this.dropdownVisible = false;
    },
    selectAnimation(animation) {
      this.selectedAnimation = animation;
      this.dropdownVisible = false;
    },
    async fetchCardDetails(cardId) {
      try {
        const response = await axios.get(`/api/onetimecard/${cardId}`);
        const cardDetails = response.data; // Assuming the API response contains card details


        await this.fetchAnimations();
        await this.fetchCards();
        // Populate the fields with retrieved card details
        this.selectedAnimation = this.animationList.find(animation => animation.id === cardDetails.animation);
        this.selectedCard = this.cardList.find(card => card.id === cardDetails.card);
        this.formattedBirthDay = this.formatDate(cardDetails.date); // Format the date
        this.text = cardDetails.text;

      } catch (error) {
        console.error("Error fetching card details:", error);
      }
    },
    async fetchAnimations() {
      try {
        const response = await axios.get("/api/animation");
        this.animationList = response.data;
      } catch (error) {
        console.error("Error fetching animations:", error);
      }
    },
    async fetchCards() {
      try {
        const response = await axios.get("/api/card");
        this.cardList = response.data.filter(card => card.category === this.selectedCategory);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    },
    formatDate(dateString) {
      // Format the date string if needed
      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : `${month}`;
      let day = date.getDate();
      day = day < 10 ? `0${day}` : `${day}`;
      return `${year}-${month}-${day}`;
    },
    deleteOneTimeCard(){
      const cardId = this.$route.query.cardId;

      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this card!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3498db',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/onetimecard?id=${cardId}`)
              .then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'One-Time card deleted successfully',
                  showConfirmButton: false,
                  timer: 1500
                });
                router.push(`/employee/onetimecard?employeeId=${this.$route.query.employeeId}`);
              })
              .catch((error) => {
                console.error("Error deleting one-time card:", error);
              });
        }
      });
    },
    saveChanges() {
      if (
          !this.text ||
          !this.selectedCard ||
          !this.formattedBirthDay ||
          !this.selectedAnimation
      ) {
        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }


      const oneTimeCardData = {
        id: this.$route.query.cardId,
        text: this.text,
        card: this.selectedCard.id,
        employeeId: this.$route.query.employeeId, // You need to set the actual employeeId,
        animation: this.selectedAnimation.id,
        date: this.formattedBirthDay,
        category: this.selectedCategory, // Include selected category in the API request
      };

      // Make API request to save one-time card
      axios
          .put("/api/onetimecard", oneTimeCardData)
          .then((response) => {
            // Handle success
            console.log("One-time card saved:", response.data);
            Swal.fire({
              icon: 'success',
              title: 'One-Time card added successfully',
              showConfirmButton: false,
              timer: 1500
            });
            router.push(`/employee/onetimecard?employeeId=${this.$route.query.employeeId}`);
          })
          .catch((error) => {
            // Handle error
            console.error("Error saving one-time card:", error);
          });
    },
  },
};
</script>

<style scoped>
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 75px 20px;
}

.back-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}

.card-layout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 40vw;
  margin: 50px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.form-field {
  margin: 15px 0;
  position: relative;
  width: 30%;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
  cursor: pointer;
  background-color: white;
  font-size: 0.90em;
}

.custom-dropdown:hover {
  background-color: #ecf0f1;
}

.card-image {
  width: 30px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Adjust spacing between image and title */
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  overflow: auto;
  max-height: 500px;
  top: 100%;
  margin-left: -11px;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content img {
  transform: none;
  margin-left: 0;
  transition: none;
}

.dropdown-content div:hover img {
  position: fixed;
  top: 53%;
  left: 36.2%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
}

.dropdown-content div:hover p {
  margin-left: 13%;
}

.dropdown-content div p {
  margin-left: 0;
  text-align: center;
}

.dropdown-content div:hover {
  background-color: #ecf0f1;
}

/* Show dropdown content on hover */
.custom-dropdown:hover .dropdown-content {
  display: block;
}

.selectTitle {
  margin-left: 30%;
}
.custom-dropdown::after {
  content: '\25BC'; /* Unicode for a right-pointing triangle */
  font-size: 0.8em;
  margin-left: auto;
  margin-right: 10px;
  color: #3498db; /* Color of the arrow */
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
}

.character-count {
  position: absolute;
  top: 15px;
  color: #3498db;
  font-size: 0.8em;
  margin-top: 20px;
}

.text-input-container {
  position: relative;
}

.textinputfield {
  margin-left: 0;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

.delete-icon {
  cursor: pointer;
  position: absolute;
  top: 12%;
  right: 30%;
  color: red;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .add-card-container {
    width: 50vw;
  }

  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .add-card-container {
    width: 80vw;
  }

  .inputfield {
    width: 60vw;
  }
}
</style>
