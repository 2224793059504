<template>
  <div>
    <nav class="navbar">
      <div class="nav-items">
        <router-link v-if="!showBirthdayLink && !showAnimationLink" to="/company/employees" class="nav-item">Employee List</router-link>
        <router-link v-if="!showBirthdayLink && !showAnimationLink" to="/company" class="nav-item">Company Info</router-link>
        <router-link v-if="!showBirthdayLink && !showAnimationLink" to="/cardsandanimations" class="nav-item">Available cards & animations</router-link>
        <router-link v-if="showBirthdayLink && !showAnimationLink" to="/employees/birthdaynextmonth" class="nav-item">Cards next month</router-link>
        <router-link v-if="showBirthdayLink && !showAnimationLink" to="/cards" class="nav-item">Cards</router-link>
        <router-link v-if="!showBirthdayLink && showAnimationLink" to="/animation" class="nav-item">Animation</router-link>
      </div>
      <router-link to="/" class="nav-item ml-auto" @click="handleLogout">Logout</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      showBirthdayLink: false,
      showAnimationLink: false,
    };
  },
  mounted() {
    this.showBirthdayLinkMethod();
    this.showAnimationLinkMethod();
  },
  methods: {
    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('hrId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('defaultCardId')
      localStorage.removeItem('cardProducentToken')
      localStorage.removeItem('tosterAdminToken')
      this.$router.push('/');
    },
    showBirthdayLinkMethod() {
      // Check if cardProducentToken is equal to the predefined value
      const cardProducentToken = localStorage.getItem('cardProducentToken');
      const isCardProducentTokenValid = cardProducentToken === process.env.VUE_APP_CARD_PRODUCENT_TOKEN;

      // Set showBirthdayLink based on the condition
      this.showBirthdayLink = isCardProducentTokenValid;
    },
    showAnimationLinkMethod(){
      const tosterAdminToken = localStorage.getItem('tosterAdminToken');
      const isTosterAdminTokenValid = tosterAdminToken === process.env.VUE_APP_TOSTER_ADMIN_TOKEN;

      this.showAnimationLink = isTosterAdminTokenValid;
    }
  }
}
</script>

<style scoped>
.navbar {
  background-color: #3498db;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin: -8px;
}

.nav-items {
  display: flex;
  justify-content: flex-start;
}

.nav-item{
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-item:hover {
  background-color: #2980b9;
}
</style>
