<template>
  <div>
    <NavBar />
    <div class="add-card-container">
      <h1>Add a Card</h1>
      <form @submit.prevent="saveCard">
        <div class="extra-images-container">
          <div class="form-field extra-image">
            <label for="cardTitle">*Card Title:</label>
            <input class="inputfield" v-model="title" type="text" id="cardTitle" required>
          </div>
          <div class="form-field extra-image">
            <label for="cardProcuctNumber">*Product number:</label>
            <input class="inputfield" v-model="productNumber" type="text" id="cardProcuctNumber" required>
          </div>
        </div>
        <div class="form-field">
          <label for="cardImageUrl">*Main image URL:</label>
          <input class="inputfield topinputfield" v-model="imageUrl" type="text" id="cardImageUrl" required>
        </div>
        <div class="form-field">
          <label>Description:</label>
          <textarea class="inputfield description-box" v-model="description"></textarea>
        </div>
        <h3>Extra images:</h3>
        <div class="extra-images-container">
          <div class="form-field">
            <label for="firstCardImageUrl">First extra image URL:</label>
            <input class="inputfield" v-model="e1imageUrl" type="text" id="firstCardImageUrl">
          </div>
          <div class="form-field extra-image">
            <label for="secondCardImageUrl">Second extra image URL:</label>
            <input class="inputfield" v-model="e2imageUrl" type="text" id="secondCardImageUrl">
          </div>
          <div class="form-field">
            <label for="thirdCardImageUrl">Third extra image URL:</label>
            <input class="inputfield" v-model="e3imageUrl" type="text" id="thirdCardImageUrl">
          </div>
        </div>
        <div class="extra-images-container">
          <div class="form-field extra-image">
            <label for="fourthCardImageUrl">Fourth extra image URL:</label>
            <input class="inputfield" v-model="e4imageUrl" type="text" id="fourthCardImageUrl">
          </div>
          <div class="form-field extra-image">
            <label for="fifthCardImageUrl">Fifth extra image URL:</label>
            <input class="inputfield" v-model="e5imageUrl" type="text" id="fifthCardImageUrl">
          </div>
        </div>
        <div class="form-field">
          <label for="cardCategory">*Category:</label>
          <select class="inputfield topinputfield" v-model="category" id="cardCategory" required>
            <option value="">Select a category</option>
            <option value="Birthday">Birthday</option>
            <option value="Wedding">Wedding</option>
            <option value="Birth Child">Birth Child</option>
            <option value="Employed Jubilee">Employed Jubilee</option>
            <option value="Retirement">Retirement</option>
          </select>
        </div>

        <button class="save-button" type="submit">Save</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "CardAddPage",
  components: {
    NavBar,
  },
  data() {
    return {
      title: "",
      productNumber: "",
      imageUrl: "",
      description: "",
      e1imageUrl: "",
      e2imageUrl: "",
      e3imageUrl: "",
      e4imageUrl: "",
      e5imageUrl: "",
      category: "",
    };
  },
  methods: {
    saveCard() {
      const cardData = {
        title: this.title,
        productNumber: this.productNumber,
        imageUrl: this.imageUrl,
        description: this.description,
        e1imageUrl: this.e1imageUrl,
        e2imageUrl: this.e2imageUrl,
        e3imageUrl: this.e3imageUrl,
        e4imageUrl: this.e4imageUrl,
        e5imageUrl: this.e5imageUrl,
        category: this.category,
      };

      // Make a request to retrieve all cards
      axios.get('/api/card')
          .then(response => {
            // Check if any card has the same product number
            const cardWithSameProductNumber = response.data.find(card => card.productNumber === this.productNumber);

            if (cardWithSameProductNumber) {
              // Display an error message if a card with the same product number already exists
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'A card with the same product number already exists.',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              // Proceed to save the new card if no card with the same product number is found
              axios.post('/api/card', cardData)
                  .then(() => {
                    // Handle success, e.g., show a success message
                    Swal.fire({
                      icon: 'success',
                      title: 'Card added',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    console.log("Card Data:", cardData);

                    router.push('/cards');
                  })
                  .catch(error => {
                    // Handle error, e.g., show an error message
                    console.error('Error saving card:', error);
                    Swal.fire({
                      icon: 'error',
                      title: 'Error saving card',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  });
            }
          })
          .catch(error => {
            console.error('Error fetching cards:', error);
            // Handle error, e.g., show an error message
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while fetching cards.',
            });
          });
    },
  },
};
</script>

<style scoped>
.add-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 35vw;
  margin: 40px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}
h3{
  color: #3498db;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}
.extra-images-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.extra-image{
  margin-left: 5px;
  margin-right: 5px;
}

.topinputfield{
  width: 40%;
  margin-left: 30%;
}

.description-box{
  resize: vertical;
  min-height: 9vh;
  width: 100%;
  margin-left: 0;
}

@media (max-width: 1000px) {
  .add-card-container {
    width: 50vw;
  }
  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .add-card-container {
    width: 80vw;
  }
  .inputfield {
    width: 60vw;
  }
}
</style>
