import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import axios from 'axios';

// Stop error ResizeObserver
const debounce = (callback, delay) => {
    let tid;
    return function (...args) {
        const ctx = this;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
    }
};

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            router.push('/');
        }
        return Promise.reject(error);
    }
);

createApp(App).use(router).mount('#app')
