<template>
  <div>
    <NavBar />
    <div class="add-animation-container">
      <h1>Add New Animation</h1>
      <form @submit.prevent="saveAnimation">
        <div class="form-field">
          <label for="animationName">*Animation Name:</label>
          <input class="inputfield" v-model="name" type="text" id="animationName" required>
        </div>
        <div class="form-field">
          <label for="animationUrl">*Animation URL:</label>
          <input class="inputfield" v-model="url" type="text" id="animationUrl" required>
        </div>
        <button class="save-button" type="submit">Save Animation</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "AnimationAddPage",
  components: {
    NavBar,
  },
  data() {
    return {
      name: '',
      url: '',
    };
  },
  methods: {
    saveAnimation() {
      const animationData = {
        name: this.name,
        url: this.url,
      };

      axios.post('/api/animation', animationData)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Animation added',
              showConfirmButton: false,
              timer: 1500
            });
            router.push('/animation'); // Adjust the route accordingly
          })
          .catch(error => {
            console.error('Error saving animation:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error saving animation',
              showConfirmButton: false,
              timer: 1500
            });
          });
    },
  },
};
</script>

<style scoped>
.add-animation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 30vw;
  margin: 50px auto;
}

h1 {
  font-size: 2em;
  color: #3498db;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

label {
  font-size: 1em;
  color: #3498db;
}

.inputfield {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3498db;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  outline: none;
}

.save-button {
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  border-color: #3498db;
  background-color: #3498db;
  color: #fff;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #2980b9;
}

@media (max-width: 1000px) {
  .add-animation-container {
    width: 50vw;
  }
  .inputfield {
    width: 35vw;
  }
}

@media (max-width: 600px) {
  .add-animation-container {
    width: 80vw;
  }
  .inputfield {
    width: 60vw;
  }
}
</style>
